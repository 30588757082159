import { formatCurrency } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { EndPoints, GAME_TYPES, limits, MISC, TurfEndpoints } from 'src/app/configs';
import { token } from 'src/app/interfaces/token';
import { SystemConfigs } from 'src/app/utilities/utils';
import { AuthParams, RABBIT_CONFIG, USER_RABBIT_CONFIG } from 'src/environments/environment';
import { ERROR_LIB } from './ERROR_LIB';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  TurfEndpoints: any = {};
  EndPoints: any = {};
  PROXIES: any = {};
  tarif = 0;
  private _LIMITS: any;
  private _useGateway = false;
  public whiteIconTheme = false;
  RABBIT_CONFIG: any;
  USER_RABBIT_CONFIG: any;
  private _usePromoCode = 0
  private _phoneNumberLength = 9;
  private _quick_signup_enabled = 0;
  private _login_required;
  public AuthParams;
  GAME_TYPES: any;
  private _min_age: number;
  private _age_verification_required: any = 0;
  private _USER_AUTHORISATION: token = {access_token: "", refresh_token:"", expiry:new Date()};
  public ClientSystemConfigs = SystemConfigs;
  public nationale_types_offered: any[] = new Array(0);
  private _quick_reg_input_fields: any = new Array(0);
  private _has_bonuses: number = 0;
  checkBalance: any;

  public get has_bonuses(): number {
    return this._has_bonuses;
  }
  public set has_bonuses(value: number) {
    this._has_bonuses = value;
  }

  public get quick_reg_input_fields(): any {
    return this._quick_reg_input_fields;
  }
  public set quick_reg_input_fields(value: any) {
    this._quick_reg_input_fields = value;
  }

  public get site_name() {
    return this._site_name;
  }
  public set site_name(value) {
    this._site_name = value;
  }
  public get age_verification_required() {
    return this._age_verification_required;
  }
  public set age_verification_required(value) {
    this._age_verification_required = value;
  }
  public get useGateway() {
    return this._useGateway;
  }
  public set useGateway(value) {
    this._useGateway = value;
  }
  public get min_age(){
    return this._min_age;
  }
  public set min_age(val: number){
    this._min_age = val
  }
  public get login_required(){
    return this._login_required;
  }
  public set login_required (value){
    this._login_required = value;
  }
  public get usePromoCode() {
    return this._usePromoCode
  }
  public set usePromoCode(value) {
    this._usePromoCode = value;
  }
  public get quick_signup_enabled() {
    return this._quick_signup_enabled;
  }
  public set quick_signup_enabled(value) {
    this._quick_signup_enabled = value;
  }
  public get phoneNumberLength(){
    return this._phoneNumberLength;
  }
  public set phoneNumberLength(value){
    this._phoneNumberLength = value;
  }
  public get USER_AUTHORISATION(): token {
    return this._USER_AUTHORISATION;
  }
  public set USER_AUTHORISATION(value: token) {
    this._USER_AUTHORISATION = value;
  }

  public get country_code() {
    return this._country_code;
  }
  public set country_code(value) {
    this._country_code = value;
  }
  public get ctrl(): Array<any> {
    return this._ctrl;
  }
  public set ctrl(value: Array<any>) {
    this._ctrl = value;
  }
  public get bet_activated() {
    return this._bet_activated;
  }

  public set bet_activated(value) {
    this._bet_activated = value;
  }

  public get system_kill_time() {
    return this._system_kill_time;
  }

  public set system_kill_time(value) {
    this._system_kill_time = value;
  }

  public get lastmessagetime() {
    return this._lastmessagetime;
  }

  public set lastmessagetime(value) {
    this._lastmessagetime = value;
  }

  public get show_tax() {
    return this._show_tax;
  }
  public set show_tax(value) {
    this._show_tax = value;
  }

  public get max_event() {
    return this._max_event;
  }
  public set max_event(value) {
    this._max_event = value;
  }
  public get min_amount_bonus() {
    return this._min_amount_bonus;
  }
  public set min_amount_bonus(value) {
    this._min_amount_bonus = value;
  }

  public get max_combined_odds() {
    return this._max_combined_odds;
  }
  public set max_combined_odds(value) {
    this._max_combined_odds = value;
  }

  public get aply_tax_based_winning() {
    return this._aply_tax_based_winning;
  }
  public set aply_tax_based_winning(value) {
    this._aply_tax_based_winning = value;
  }
  public get tax_based_winning() {
    return this._tax_based_winning;
  }
  public set tax_based_winning(value) {
    this._tax_based_winning = value;
  }



  public get tax_active() {
    return this._tax_active;
  }
  public set tax_active(value) {
    this._tax_active = value;
  }


  public get stake_step() {
    return this._stake_step;
  }

  public set stake_step(value) {
    this._stake_step = value;
  }

  public get max_stake() {
    return this._max_stake;
  }
  public set max_stake(value) {
    this._max_stake = value;
  }

  public get max_pot_win() {
    return this._max_pot_win;
  }
  public set max_pot_win(value) {
    this._max_pot_win = value;
  }
  public get min_possible_stake() {
    return this._min_possible_stake;
  }
  public set min_possible_stake(value) {
    this._min_possible_stake = value;
  }


  public get allow_simple_Bets() {
    return this._allow_simple_Bets;
  }
  public set allow_simple_Bets(value) {
    this._allow_simple_Bets = value;
  }


  public get bonus() {
    return this._bonus;
  }
  public set bonus(value) {
    this._bonus = value;
  }

  public get tax() {
    return this._tax;
  }
  public set tax(value) {
    this._tax = value;
  }
  public get country() {
    return this._country;
  }
  public set country(value) {
    this._country = value;
  }
  public get odds_precision() {
    return this._odds_precision;
  }
  public set odds_precision(value) {
    this._odds_precision = value;
  }

  public get amountprecision() {
    return this._amountprecision;
  }
  public set amountprecision(value) {
    this._amountprecision = value;
  }

  public get coupon_validity() {
    return this._coupon_validity;
  }
  public set coupon_validity(value) {
    this._coupon_validity = value;
  }
  public get currency() {
    return this._currency;
  }
  public set currency(value) {
    this._currency = value;
  }
  public get LIMITS(): any {
    return this._LIMITS;
  }
  public set LIMITS(value: any) {
    this._LIMITS = value;
  }
  RECEIVER_PHONENUMBER = '';

  constructor(private http: HttpClient, private translate: TranslateService) {
// console.log('Before call from config service ');


  }

  private _currency = '';
  private _country_code = '';
  private _country = "GN";
  // private _currency = 'XOF';
  private _coupon_validity = '2';
  private _amountprecision = 0.01;
  private _odds_precision = 2;
  private _tax = 25;
  private _bonus: any = {};
  private _allow_simple_Bets = false;
  private _min_possible_stake = 0;
  private _max_pot_win = 300000;
  private _max_stake = 10000000;
  private _stake_step = 50;
  private _tax_active;

  private match_kill_time = 15000;
  private _tax_based_winning = 1000000000000000;
  private _max_event;

  private _min_amount_bonus;
  private _aply_tax_based_winning = false;
  private _max_combined_odds;
  private _show_tax = false;
  private _ctrl: Array<any> = [];
  private _lastmessagetime;
  private _system_kill_time = 20000;
  private _bet_activated = true;
  private _site_name = "SPORTCASH";
  expiry: any;
  channel: any;
  lang:any = 'fr'
  RAB_CONFIG = {
    // USER: "root",
    // PASSWD: 'admin'
    // USER: 'root',
    // PASSWD: 'uRZVxtB!6fjN'
    USER: 'loto_ws_user',
    PASSWD: 'loto_ws_pwd'
  }
 games = {
    'COUPLE GAGNANT': 'CGC3',
    'COUPLE PLACE CM3': 'CPC3',
    'TIERCE CM3': 'TRC3',
    'QUARTE CM3': 'QRC3',
    'QUINTE CM3': 'QNC3',
    'MULTI CM3': 'MULTIC3',
    'QUARTE PLUS CM3': 'QRPC3',
    'QUINTE PLUS CM3': 'QNPC3',
   '2 SUR 4 CM3': '24C3',
    'TIERCE TE2 CM3': 'TRTE2C3',
    TRIO: 'TRIO',
    'PICK 5': 'P5',
    'JUMELE GAGNANT': 'JG',
    'JUMELE ORDRE': 'JO',
    'JUMELE PLACE': 'JP',
    'SIMPLE GAGNANT': 'SG',
    'SIMPLE PLACE': 'SP',
  };
$config: Subject<any> = new Subject();
$tarif: Subject<any> = new Subject();
  public getErrorMessage(code) {
    for (const key in ERROR_LIB) {

      if (ERROR_LIB[key] === code) {
        return key;
      }
    }

    return code;
  }
  window: any = window;
  public decorateUrl(urlString) {
    var ga = this.window[this.window['GoogleAnalyticsObject']];
    var tracker;
    if (ga && typeof ga.getAll === 'function') {
      tracker = ga.getAll()[0]; // Uses the first tracker created on the page
      urlString = (new this.window.gaplugins.Linker(tracker)).decorate(urlString, true, true);
    }
    return urlString;
  }

  loadConfigs() {
    this.RECEIVER_PHONENUMBER = MISC.RECEIVER_PHONENUMBER;
    this.expiry = MISC.expiry;
    this.channel = MISC.channel;
    return new Promise((resolve, reject) => {
    this.http.get('assets/config/config.json').subscribe(
      (res: any) => {
       this.PROXIES = res.PROXIES;
       this.EndPoints = EndPoints;
       this.RABBIT_CONFIG = RABBIT_CONFIG;
       this.USER_RABBIT_CONFIG = USER_RABBIT_CONFIG;
       this.AuthParams = AuthParams;
       this.GAME_TYPES = GAME_TYPES;
       this.TurfEndpoints = TurfEndpoints;
       this.PROXIES.sportcash_1 = this.PROXIES.sportcash + "/v2";
       this.EndPoints.userManagementEndpoint = this.PROXIES.userManagement_site;
       this.checkBalance = this.PROXIES.check_account_balance;
       this.LIMITS = limits;
       this.nationale_types_offered = this.stringToArray(res.nationales);
       this.$config.next(res);
       this.$config.complete();
       resolve(res);
      });
     });
  }

  formatCurrency(value: number, digitsInfo = '1.0-0') {
    let locale = "en", currency = this.currency, currencyCode = this.currency;
    return formatCurrency(value? value: 0, locale, currency, currencyCode, digitsInfo).replace(/[^0-9\.,-]+/g, "")
  }

  getNaturalAmountStr(amount: number = 0) {
    if (!amount) return "0";
    const million = amount / Math.pow(10, 6);
    const thousand = amount / Math.pow(10, 3);
    const billion = amount / Math.pow(10, 9);
    if (billion >= 1)
      return `${billion} ${this.translate.instant((billion == 1) ? 'billion' : 'billions')}`;
    if (million >= 1)
      return `${million} ${this.translate.instant((million == 1) ? 'million' : 'millions')}`;
    else if (thousand >= 1)
      return this.formatCurrency(amount, "2.0-0")
    else
      return amount.toString();
  }


  stringToArray(str: string, del: string = ','): any {
    if (typeof(str) == 'object') return str;
    return str.split(del);
  }
}
