import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../providers/config/config.service';

@Pipe({
  name: 'imageLoader'
})
export class ImageLoaderPipe implements PipeTransform {

  constructor(private configs: ConfigService){ }

  transform(value: any, args?: any): any {
    let path = '';
      if(value && value.indexOf('assets/')>-1){
        path = value;
      }
      else if(value){
        path = value.toString().indexOf("https://") > -1 ? value : this.configs.PROXIES.image_gateway + (value.includes('.') ? value: 'a' + value + '.png');
      }
    return path;
  }

}
