import { ConfigService } from './../../config/config.service';
import { SportServiceService } from 'src/app/providers/services/sport-service/sport-service.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { HttpService } from './../../../wrappers/Http';
import { BetObj, Match, Proposition, BetInput } from './../../../interfaces/betObject';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BetslipServiceService {
  data: { [key: string]: any; };

  betChoices: Array<BetObj> = [];
  isProcessing = false;
  integralTicket = false;
  msg: any;
  couponCode: any = '';
  showCoupon = false;
  propositionList: any = {};
  playMode: any;
  minticketBonus = "0";
  maxTicketBonus = "0";
  sumticketBonus = "0";


  minticketPotWin = '0';
  maxTicketPotWin = '0';
  sumticketPotWin = '0';

  taxPerTicket = '0';
  finalWin = 0;

  potentialWinning = '0';
  potentialWinningBonus = '0';
  potentialWinningBonusTax = '0';

  minOdd = '1.0';
  maxOdd = '1.0';
  allOddsSum = '1.0';

  savedTickets: Array<any> = [];
  ticketValues: any = {};
  mise = 0;
  totMise = 1;
  odds: any;
  betObj: BetInput;
  tax = 0.0;
  activeBonusobj: any = {
    value: 0
  };
  numTickets: number;
  couponMsg: string;
  bonusValues: {};
  constructor(
    private requestHandler: HttpService,
    public modalService: ModalService,
    public configService: ConfigService,
    public sportService: SportServiceService,
    public translate: TranslateService,
    private userService: UserManagerService
  ) {
    this.mise = this.configService.min_possible_stake;
    this.totMise = this.configService.min_possible_stake;
  }

  addSlip(match: Match, proposition: Proposition, market?: any) {

    this.showCoupon = false;
    this.couponMsg = undefined;
    if (market) {
      proposition.market.specifier = market.specifier
    }
    match.tournament = this.sportService.activeTournaments[0];
    match.category = this.sportService.activeGroup;
    proposition.match_number = match.number;
    if (this.propositionList.hasOwnProperty(match.id + '_' + proposition.market.id + '_' + proposition.outcome_id)) {
      delete this.propositionList[match.id + '_' + proposition.market.id + '_' + proposition.outcome_id];

      const matchBets = this.betChoices.filter(matchBet => {
        return matchBet.match.id === match.id;
      });

      _.remove(matchBets[0].propositions, (el) => {
        return (el.id === proposition.id);
      });

    } else {


      const matchBets = this.betChoices.filter(matchBet => {
        return matchBet.match.id === match.id;
      });
      if (matchBets.length === 0) {
        if (this.betChoices.length == this.configService.max_event) {
          this.msg = "max_events-selected";
          return;

        }
        this.betChoices.unshift({ match, propositions: [proposition] });
      } else {
        matchBets[0].propositions.push(proposition);
      }
      this.propositionList[match.id + '_' + proposition.market.id + '_' + proposition.outcome_id] = proposition;

      // if (market) {
      //   proposition.market = market;
      // }
    }
    _.remove(this.betChoices, (choice) => {

      return choice.propositions.length === 0;
    });
    this.calculateOdds();

  }
  formBetCombinations(): any {

    return this.formCombinations(this.getAllTickets());
  }
  removeBet(index, bet, el?: any) {
    this.betChoices.splice(index, 1);
    bet.propositions.forEach(element => {
      delete this.propositionList[element.id];
    });
    this.calculateOdds();
    this.couponMsg = undefined;
  }
  getAllTickets() {
    const propositionGroupsContainer = [];

    this.betChoices.forEach(choice => {
      if (!choice.match.isFinished) {
        propositionGroupsContainer.push(choice.propositions);
      }
    });
    return this.cartesionProduct(propositionGroupsContainer);
  }
  removeBetPropostion(index, propIndex, proposition, match) {
    this.betChoices[index].propositions.splice(propIndex, 1);
    if (!this.betChoices[index].propositions.length) {
      this.removeBet(index, this.betChoices[index]);
    }
    delete this.propositionList[match.id + '_' + proposition.market.id + '_' + proposition.outcome_id];

    this.calculateOdds();
  }
  formCombinations(tickets: Array<any>): string {
    let pick = '';
    tickets.forEach(ticket => {
      pick = pick === '' ? this.formTicketPick(ticket) : pick + 'T' + this.formTicketPick(ticket)
    });
    return pick;
  }

  formTicketPick(ticket: Array<Proposition>): string {
    let pick = '';
    ticket.forEach(prop => {
      pick = pick === '' ? prop.match_number + '*' + prop.market.id + '*' + prop.outcome_id :
        pick + 'B' + prop.match_number + '*' + prop.market.id + '*' + prop.outcome_id;
    });
    return pick;
  }


  addToSlip(betObj: BetObj) {
    this.betChoices.unshift(betObj);
    this.calculateOdds();
  }
  clearCart() {
    this.betChoices = [];
    this.propositionList = {};
    this.calculateOdds();
  }
  calculateOdds() {

    this.ticketValues = {};
    const tickets = this.getAllTickets();
    this.numTickets = tickets.length;
    this.totMise = this.mise * this.numTickets;
    this.integralTicket = tickets.length > 1;
    tickets.forEach(ticket => {
      let ticketValue = this.getTicketValue(ticket);
      ticketValue = ticketValue > this.configService.max_combined_odds ? this.configService.max_combined_odds : ticketValue;
      this.ticketValues[ticketValue] = ticket;
    });

    let oddsValues = Object.keys(this.ticketValues);

    oddsValues = oddsValues.sort((val1, val2) => {
      return Number(val1) - Number(val2);
    });

    this.minOdd = new Decimal(oddsValues[0]).toFixed(this.configService.odds_precision);
    this.maxOdd = new Decimal(oddsValues[oddsValues.length - 1]).toFixed(this.configService.odds_precision);
    this.allOddsSum = '0.0';

    oddsValues.forEach(odd => {

      this.allOddsSum = new Decimal(this.allOddsSum).add(odd).toFixed(this.configService.odds_precision);
    });

    this.odds = oddsValues[0];

    this.calculateAmount();
  }
  getTicketValue(ticket: Array<Proposition>) {
    let ticketValue = new Decimal(1.00);
    ticket.forEach(prop => {
      ticketValue = new Decimal(ticketValue).mul(prop.odds);
    });
    return ticketValue.toFixed(this.configService.odds_precision);
  }
  calculateAmount($event?) {
    this.mise = this.mise ? this.mise : 0;
    if (this.configService.max_stake && new Decimal(this.mise).greaterThan(this.configService.max_stake)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.mise = this.configService.max_stake;
    }

    if (new Decimal(this.potentialWinning).lessThan(this.configService.max_pot_win)) {
      this.potentialWinning = new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString();
    }
    if (this.integralTicket) {
      this.maxTicketPotWin = new Decimal(this.maxOdd).mul(this.mise).toNearest(this.configService.amountprecision).toString();
      this.maxTicketPotWin = new Decimal(this.maxTicketPotWin).greaterThan(this.configService.max_pot_win) ?
        new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString()
        : this.maxTicketPotWin;
      this.minticketPotWin = new Decimal(this.minOdd).mul(this.mise).toNearest(this.configService.amountprecision).toString();
      this.minticketPotWin = new Decimal(this.minticketPotWin).greaterThan(this.configService.max_pot_win) ?
        new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString()
        : this.minticketPotWin
      this.minticketPotWin = new Decimal(this.minticketPotWin).greaterThan(this.configService.max_pot_win) ?
        new Decimal(this.configService.max_pot_win).toFixed(this.configService.amountprecision)
        : this.minticketPotWin
      this.sumticketPotWin = new Decimal(this.allOddsSum).mul(this.mise).toNearest(this.configService.amountprecision).toString();
      this.sumticketPotWin = new Decimal(this.sumticketPotWin).greaterThan(this.configService.max_pot_win) ?
        new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString()
        : this.sumticketPotWin
      this.calculateGainWithBonus();
      return;
    }
    this.potentialWinning = '1.0';

    this.potentialWinning = new Decimal(this.mise).mul(this.odds)
      .toNearest(this.configService.amountprecision).toString();
    this.potentialWinning = new Decimal(this.potentialWinning).lessThan(this.configService.max_pot_win)
      ? this.potentialWinning : new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString();
    this.calculateGainWithBonus();
  }
  calculateGainWithBonus() {
    this.activeBonusobj = this.getValidBonus();
    this.minticketBonus = new Decimal(this.bonusValues && this.bonusValues[this.minOdd] ? this.bonusValues[this.minOdd].percent : 0)
      .mul(this.minticketPotWin).div(100)
      .toNearest(this.configService.amountprecision).toString();


    this.maxTicketBonus = new Decimal(this.bonusValues && this.bonusValues[this.maxOdd] ? this.bonusValues[this.maxOdd].percent : 0)
      .mul(this.maxTicketPotWin).div(100)
      .toNearest(this.configService.amountprecision).toString();

    // maxTicketBonus = 0;
    // this.sumticketBonus = new Decimal(0;
    let ticketSum = 0;
    let oddsValues = Object.keys(this.ticketValues);
    oddsValues.forEach(odd => {
      let ticketWin = new Decimal(odd).mul(this.mise);
      ticketSum = ticketSum + new Decimal(this.bonusValues && this.bonusValues[odd] ? this.bonusValues[odd].percent : 0)
        .mul(ticketWin).div(100).toNumber();
    });

    this.sumticketBonus = new Decimal(ticketSum).toNearest(this.configService.amountprecision).toString();

    ticketSum = 0;
    oddsValues.forEach(odd => {
      let ticketWin = new Decimal(odd).mul(this.mise);
      ticketSum = ticketSum + new Decimal(this.bonusValues && this.bonusValues[odd] ? this.bonusValues[odd].percent : 0)
        .mul(ticketWin).div(100).add(ticketWin).toNumber();
    });

    this.potentialWinningBonus = new Decimal(ticketSum).toNearest(this.configService.amountprecision).toString();
    this.potentialWinningBonus = new Decimal(this.potentialWinningBonus).greaterThan(this.configService.max_pot_win) ? this.configService.max_pot_win + "" : this.potentialWinningBonus
    // this.potentialWinningBonus = new Decimal(this.bonusValues[0])
    //   .mul(this.potentialWinning).div(100).add(this.potentialWinning).toNearest(this.configService.amountprecision).toString();
    // this.potentialWinningBonus = new Decimal(this.potentialWinningBonus).greaterThan(this.configService.max_pot_win) ?
    //   new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString()
    //   : this.potentialWinningBonus

    if (this.configService.tax_active == 1) {
      this.calculateTax();
    }

  }
  getValidBonus() {
    const defaultBonus = {
      percent: 0
    };
    if (!this.configService.bonus || !this.configService.bonus.detail) {
      return defaultBonus;
    }
    const minEvt = this.configService.bonus.min_evt ? this.configService.bonus.min_evt : 2;
    const maxEvt = this.configService.bonus.min_evt ? this.configService.bonus.max_evt : 1000;
    const maxStak = this.configService.bonus.min_evt ? this.configService.bonus.max_evt : 1500000;
    //handleExeptions
    let bonuskeys = Object.keys(this.ticketValues);
    this.bonusValues = {};
    bonuskeys.forEach(key => {


      if (this.ticketValues[key].length < minEvt) {
        this.bonusValues[key] = 0
        // return defaultBonus;
      }
      if (this.ticketValues[key] > maxEvt) {
        // return defaultBonus;
        this.bonusValues[key] = 0
      }
      if (!this.configService.bonus.detail) {
        // return defaultBonus;

      }
      const validFixturesCount2 = this.validpropositionForBonus(this.ticketValues[key]);
      const bonuses = this.configService.bonus.detail.filter(element => {
        return element.evt_num === validFixturesCount2;
      });
      this.bonusValues[key] = bonuses ? bonuses[0] : undefined;
    })
    const validFixturesCount = this.validFixtureForBonus();
    const bonuses = this.configService.bonus.detail.filter(element => {
      return element.evt_num === validFixturesCount;
    });
    return bonuses ? bonuses[0] : undefined;
  }
  verifyTicket(ticket): Promise<any> {
    return this.requestHandler.Hget(this.configService.PROXIES.turf_gateway, this.configService.EndPoints.v1_verify_ticket + ticket, {});
  }
  loadBet(code: string) {
    this.couponMsg = undefined;

    if (code.trim().length === 0) {
      this.couponMsg = 'enter_valid_code';
      return;
    }
    this.isProcessing = true;
    this.requestHandler.get(this.configService.EndPoints.loadCoupon + code, {}).then(response => {
      if (response.erc === '1') {
        this.betChoices = response.data.betChoices;
        this.betChoices.forEach(choice => {
          choice.propositions.forEach(proposition => {
            this.propositionList[choice.match.id + "_" + proposition.market.id + "_" + proposition.outcome_id] = proposition
          })

        })
        this.propositionList
        this.mise = response.data.bet.amt;
        this.calculateOdds();
      } else {
        this.couponMsg = response.msg;
      }
    }).catch(err => {
      this.couponMsg = 'pls_try_later';
      // this.modalService.isSuccess = false;
      // this.modalService.msg = err[0].msg;
      // this.modalService.showModal();
    }).finally(() => {
      this.isProcessing = false;
    });
  }
  saveBet(isCoupon?: boolean) {
    // console.log(this.configService.bet_activated);
    if (!this.configService.bet_activated) {
      this.msg = "cant_place_bet_moment";
      return;
    }
    let me = this;

    if (!this.validateBet()) {
      return;
    }
    if (isNaN(this.mise)) {
      this.msg = 'invalid_mise';
      return false;
    }
    if (this.userService.userData && this.userService.userData.balances && this.userService.userData.balances.total < (this.numTickets * this.mise)) {
      this.msg = 'ERROR_CODE_SOLDE_INSUFFISANT';
      return false;
    }
    if (this.mise < this.configService.min_possible_stake) {
      this.msg = 'min_stake';
      return false;
    }
    let ticketODss = Object.keys(this.ticketValues);
    if (this.configService.ctrl) {
      this.configService.ctrl.forEach(ctrl => {
        ticketODss.forEach(ticket => {
          if (ticket > ctrl.min && ticket <= ctrl.max) {
            if (this.mise > ctrl.stake) {
              this.msg = this.translate.instant('max_stake_allowed_odds_between', { maxstake: formatCurrency(ctrl.stake, 'en', 'XOF', "XOF").replace(/[^0-9\.,-]+/g, "") });
            }
          }

        });
      });
      if (this.msg)
        return false;

    }

    if (this.configService.max_pot_win && this.potentialWinning && new Decimal(this.potentialWinning).greaterThan(this.configService.max_pot_win)) {
      this.potentialWinning = new Decimal(this.configService.max_pot_win).toNearest(this.configService.amountprecision).toString();
      return false;
    }
    if (!new Decimal(this.mise).modulo(this.configService.stake_step).equals(0)) {
      this.msg = 'stake_multiple_of';
      return false;
    }

    if (!this.userService.userData && !isCoupon) {
      this.msg = 'Login_to_place_this_BEt';
      return;

    }

    this.msg = '';
    this.formBetData();

    const couponData = isCoupon ? {
      data: {
        bet: this.betObj,
        betChoices: this.betChoices
      }
    } : {};
    this.isProcessing = true;
    if (isCoupon) {

      this.requestHandler.Post(this.configService.PROXIES.turf_gateway.substring(0, this.configService.PROXIES.turf_gateway.length - 1) + this.configService.EndPoints.saveCoupon, couponData).then(data => {
        if (data.erc === '1') {
          this.couponCode = data.code;
          this.showCoupon = true;
          me.cancelBets();
          me.initFields();
        }
      }).catch(err => {
        this.modalService.isSuccess = false;
        this.modalService.msg = err.error[0].msg;
        this.modalService.showModal();
      }).finally(() => {
        this.isProcessing = false;
      });
    } else {

      this.requestHandler.Post(this.configService.PROXIES.turf_gateway.substring(0, this.configService.PROXIES.turf_gateway.length - 1) + this.configService.EndPoints.saveBet + this.parseParams(this.betObj), this.betObj).then(data => {

        if (data && data.length > 0 && data[0] && data[0]['14'] === true || data[0]['14'] === 'true') {
          this.modalService.isError = false;
          this.modalService.isSuccess = true;
          // this.modalService.msg = 'bets_placed_successfully';
          this.msg = 'bets_placed_successfully';
          this.modalService.title = 'success';
          if (data[0].hasOwnProperty('balances')) {

            this.userService.updateUserAttribute('balances', data[0]['balances']);
          }
          // this.modalService.showModal();
          setTimeout(() => {
            me.cancelBets();
            me.initFields();
            this.msg = undefined;
          }, 3000)
          // me.cancelBets();
          // me.initFields();
        } else {
          this.modalService.isSuccess = false;
          this.modalService.isError = true;
          this.modalService.title = 'Ooopss!!!!!!!!!!!!!!!!!';
          this.modalService.msg = data !== null && data[0].msg
            ? data[0].msg
            : 'bets_failed';
          this.msg = 'bets_failed';
          // this.modalService.showModal();
        }

      }, err => {
        this.modalService.isSuccess = false;
        this.modalService.msg = ' There was an error While placing your Bet Please try again later';
        this.modalService.showModal();
      }).catch(err => {
        this.modalService.isSuccess = false;
        this.modalService.isError = true;
        // this.modalService.msg = err[0].msg;
        // this.modalService.msg = err.error[0].msg;
        this.msg = ' There was an error While placing your Bet Please try again later';
        // this.modalService.showModal();
        // this.modalService.showModal();
      }).finally(() => {
        this.isProcessing = false;
      });
    }

  }
  validateBet(): boolean {
    for (const betChoice of this.betChoices) {
      if (betChoice.match.isFinished) {
        this.msg = 'bet_invalid_matches';
        return false;
      }

      for (const proposition of betChoice.propositions) {
        if (!proposition.is_active || proposition.is_active == 0 || !proposition.market.is_active ||
          (this.sportService.cancelledMarkets[betChoice.match.id]
            && this.sportService.marketInList(proposition.market, this.sportService.cancelledMarkets[betChoice.match.id]))
        ) {
          this.msg = 'bet_invalid_matches';
          return false;
        }
      }
    }
    return true;
  }
  initFields() {
    this.mise = 0;
    this.calculateAmount();

  }
  cancelBets() {
    this.betChoices = [];
    this.propositionList = {};
  }
  formBetData() {

    this.betObj = {
      phn: undefined,
      amt: undefined,
      chl: undefined,
      tkt: 1,
      sid: undefined,
      uid: undefined,
      pwd: undefined,
      ref: undefined,
      pick: undefined,
      internal_ref: undefined,
      lang: this.configService.lang
    };
    this.betObj.pick = this.formBetCombinations();
    this.betObj.amt = this.mise;
    this.betObj.uid = this.userService.userData ? this.userService.userData.lg_AGENT_ID : '001';
    this.betObj.chl = 3;
    this.betObj.sid = this.userService.userData ? this.userService.userData.session : '';
    this.betObj.tkt = this.getTicketType(this.betObj.pick);
    this.betObj.pwd = this.userService.userData ? this.userService.userData.str_PIN + '@1' : '';
    this.betObj.phn = this.userService.userData ? (this.userService.userData.str_Phone_Number
      && this.userService.userData.str_Phone_Number.length > 0 ? this.userService.userData.str_Phone_Number : '777777777') : '';
    this.betObj.ref = new Date().getTime();
    this.betObj.internal_ref = new Date().getTime();
  }


  getTicketType(pick): any {
    if (pick.indexOf('T') > -1) {
      return 3;
    } else if (pick.indexOf('B') > -1) {
      return 2;
    } else {
      return 1;
    }

  }
  parseParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = '?';
    for (let i = 0; i < arr.length; i++) {
      queryParams = queryParams + arr[i] + '=' + data[arr[i]] + '&';
    }
    return queryParams;
  }
  cartesionProduct(argument) {
    var args = [].slice.call(argument)
      , end = args.length - 1
    var result = []

    function addTo(curr, start) {
      var first = args[start]
        , last = (start === end)

      for (var i = 0; i < first.length; ++i) {
        var copy = curr.slice()
        copy.push(first[i])

        if (last) {
          result.push(copy)
        } else {
          addTo(copy, start + 1)
        }
      }
    }

    if (args.length) {
      addTo([], 0)
    } else {
      result.push([])
    }
    return result;
  }
  calculateTax() {
    if (this.integralTicket) {

      if (this.configService.aply_tax_based_winning) {

        Object.keys(this.ticketValues).forEach(ticket => {
          let ticketWin = new Decimal(ticket).mul(this.mise).add(this.bonusValues[ticket] ? this.bonusValues[ticket] : 0);
          let ticketTax = new Decimal(0);
          this.taxPerTicket = new Decimal(0).toString();
          ticketWin = ticketWin.greaterThan(this.configService.max_pot_win) ? new Decimal(this.configService.max_pot_win) : ticketWin;
          if (ticketWin.greaterThanOrEqualTo(this.configService.tax_based_winning)) {
            ticketTax = new Decimal(ticketWin).sub(this.mise).mul(this.configService.tax).dividedBy(100);
            this.configService.show_tax = true;
          } else {
            this.configService.show_tax = false;
          }
          this.taxPerTicket = new Decimal(this.taxPerTicket).add(ticketTax).toNearest(this.configService.amountprecision).toFixed(0);
          this.potentialWinningBonusTax = new Decimal(this.sumticketPotWin)
            .sub(this.taxPerTicket).toNearest(this.configService.amountprecision).toString();
        });

      } else {

        this.taxPerTicket = new Decimal(this.sumticketPotWin).sub(this.mise).mul(this.configService.tax).dividedBy(100).toFixed(2);
        this.potentialWinningBonusTax = new Decimal(this.sumticketPotWin)
          .sub(this.taxPerTicket).toNearest(this.configService.amountprecision).toString();
      }
    } else {
      if (this.configService.aply_tax_based_winning) {
        if (Number(this.potentialWinningBonus) >= this.configService.tax_based_winning) {
          this.configService.show_tax = true;
          this.taxPerTicket = new Decimal(this.potentialWinning).sub(this.mise).mul(this.configService.tax).dividedBy(100).toFixed(2);
          this.potentialWinningBonusTax = new Decimal(this.potentialWinning)
            .sub(this.taxPerTicket).toNearest(this.configService.amountprecision).toString();
        } else {
          this.configService.show_tax = false;
        }
      } else {
        this.taxPerTicket = new Decimal(this.potentialWinning).sub(this.mise).mul(this.configService.tax).dividedBy(100).toFixed(2);
        this.potentialWinningBonusTax = new Decimal(this.potentialWinning)
          .sub(this.taxPerTicket).toNearest(this.configService.amountprecision).toString();
      }
    }
  }
  validpropositionForBonus(propositions: Array<Proposition>) {
    let count = 0;
    const min_odd = this.configService.bonus ? this.configService.bonus.min_odd : 10000000000;
    propositions.forEach(proposition => {
      if (proposition.odds >= min_odd) {
        count++;
      }
    });
    return count;
  }
  validFixtureForBonus(): any {
    let count = 0;
    const min_odd = this.configService.bonus.min_odd;
    const bets = this.betChoices;
    for (const bet of bets) {
      propositionLoop:
      for (const proposition of bet.propositions) {
        if (proposition.odds >= min_odd) {
          count++;
          continue propositionLoop;
        }
      }
    }
    return count;
  }
  changePlayMode() {
  }
}
