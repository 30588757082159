import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'turfDate'
})
export class TurfDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    moment.locale('fr')
    let time = '';
    if(value){
    time = value.split(" ")[1];
    time = time.split(":").slice(0,2).join(":");
    }
    return time
    // return moment(value).format('LT');  13-12-2020 18:03:35
    // return Math.abs(moment().diff(moment(value)))
    // return value.replace(":","h")
  }

}
