export interface Theme {
  name: string;
  useGateway: boolean;
  properties: any;
  country: string;
  whiteIcon?: boolean;
  partnerID?: string,
  contact: any;
}

const lonaguiDefaults = { promoImg: "url(assets/images/promo_3.png)" };
const pmugDefaults = { promoImg: "url(assets/images/promo_3.png)" };
const pmucDefaults = { promoImg: "url(assets/images/promo_3.png)" };
const lonaciDefaults = { promoImg: "url(assets/images/promo_3.png)" };
const tunisiaDefaults = { promoImg: "url(assets/images/promo_3.png)" };
const lonaniDefaults = { promoImg: "url(assets/images/promo_3.png)" };

export const lonagui: Theme = {
  name: "lonagui",
  useGateway: true,

  properties: {
    //-------------------------  header-----------------------------
    "--app-background-header": "#fff",
    "--app-background-base": "#fff",
    "--app-background-body": "#009d48",
    "--app-background-turf-home": "#f2f2f2",
    "--app-background-footer": "#037b3a",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#027835",
    "--app-background-nav-hover": "#009d48",
    "--app-background-nav-border-bottom": "#fcd016",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#77777a",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#eb862c",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#027835",
    "--app-background-primary-tint": "",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#027835",

    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#fa7f22",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    // "--app-color-lexique": "#403939",
    // "--app-background-lexique": "#fcd016",
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "#fa7f22",
    "--app-background-cmtJr": "#fa7f22",
    "--app-color-cmtJr": "#fff",

    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions": "#027835",
    "--app-background-border-promotions": "#fa7f22",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#fa7f22",
    // comment jouer
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#027835",
    "--app-background-second-nav-hover": "#009d48",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#027835",
    "--app-background-border-second-nav-selected": "#027835",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#77777a",
    "--app-background-rapport": "#009d48",
    "--app-background-rapport-header-border": "#027835",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#027835",
    "--app-color-top-race-CN": "#027835",
    "--app-color-top-race-time": "#b9b8ba",
    "--app-color-top-race-time-up": "#fcd016",
    "--app-background-top-race": "transparent",
    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#77777a",
    "--app-background-meeting-link": "#fff",
    "--app-color-meeting-link-selected": "#fff",
    "--app-background-meeting-link-selected": "#027835",
    "--app-background-meeting-link-borders": "#027835",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#77777a",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "#77777a",
    "--app-color-table-header-border-left": "#027835",
    "--app-background-wrap-race": "#fff",
    "--app-color-table-headings": "",
    "--app-color-table": "#77777a",
    "--app-color-table-subdetails": "#77777a",
    "--app-color-table-numbers": "#77777a",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#77777a",
    "--app-background-table-open": "#5d5d5d",
    //-------------------------  registration-----------------------------
    "--app-color-registration": "#fff",
    "--app-background-registration": "#027835",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#fff",
    "--app-color-registration-validation-error": "#fcd016",

    //-------------------------  footer-----------------------------
    "--app-color-footer-heading-1": "#00ff75",
    "--app-color-footer-body": "",
    // reset password
    "--app-color-resetpw-header": "#027835",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#009d48",
    "--app-background-profile-body": "#027835",
    "--app-color-transaction-tab-secondary": "#f8bd31",
    "--app-color-transaction-tab-primary": "#fff",
    "--app-color-profile-section-activeheader": "#f8bd31",
    "--app-background-profile-header": "#027835",
    "--app-background-profile-header-border": "#009d48",
    "--app-background-profile-section-header": "#176334",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#fff",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#027835",
    "--app-color-profile-input-border": "#176334",
    "--app-color-profile-input-lable": "#64c388",
    "--app-background-transactions-body": "#027835",
    "--app-color-transactions-header": "#64c388",
    "--app-color-transactions-header-input-border": "#64c388",
    "--app-color-transactions-header-input-lable": "#64c388",
    "--app-color-transactions-header-input": "#fff",

    "--app-background-transactions-tabs": "#027835",
    "--app-color-accounts": "#dedede",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#009d48",
    "--app-background-active-trans-method":
      "linear-gradient(90deg  , #fcd016 -1.5%, #009d48 101.5%)",
    "--app-color-methods-border-left": "#009d48",
    "--app-color-border-acc-input-focus": "#009d48",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#027835",
    "--app-background-betSummary": "#fcd016",
    "--app-color-betSummary": "#403939",
    "--app-color-yello-odd": "#fcd016",
    "--app-color-background-dropdown": "#027835",
    "--app-color-betslip": "#027835",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#037b3a",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#fcd016",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#027835",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#027835",
    "--app-color-button-secondary": "#fff",
    "--app-background-button-secondary": "#fcd016",
    "--app-color-bet-button": "#5d5d5d",
    "--app-background-bet-button": "#fcd016",
    "--app-color-rapport-button": "#77777a",
    "--app-background-rapport-button-border": "#b9b8ba",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#b9b8ba",
    "--app-color-coef-button": "#fff",
    "--app-background-coef-button": "#fcd016",
    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#027835",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#009d48",

    // -----------------------------faq -----------------------------------
    "--app-color-faq-header": "#009d48",

    // -----------------------------default imgs --------------------------
    "--img-default-promo": lonaguiDefaults.promoImg,
  },
  country: "GN",
  contact:{
    tel: '+225 07 07 07 18 89',
    email: 'hotline@lonaci.ci',
    fax: '+225 01 01 51 44 00',
    addresse: 'e'
  },

};
export const lonani: Theme = {
  name: "lonani",
  useGateway: true,

  properties: {
    //-------------------------  header-----------------------------
    "--app-background-header": "#fff",
    "--app-background-base": "#fff",
    "--app-background-body": "#009d48",
    "--app-background-turf-home": "#f2f2f2",
    "--app-background-footer": "#037b3a",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#149449",
    "--app-background-nav-hover": "#009d48",
    "--app-background-nav-border-bottom": "#fa7f22",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#77777a",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#eb862c",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#149449",
    "--app-background-primary-tint": "",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#149449",

    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#fa7f22",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    // "--app-color-lexique": "#403939",
    // "--app-background-lexique": "#F9A90E",
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "#fa7f22",
    "--app-background-cmtJr": "#fa7f22",
    "--app-color-cmtJr": "#fff",

    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions": "#149449",
    "--app-background-border-promotions": "#fa7f22",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#fa7f22",
    // comment jouer
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#fa7f22",
    "--app-background-second-nav-hover": "#009d48",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#149449",
    "--app-background-border-second-nav-selected": "#fa7f22",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#77777a",
    "--app-background-rapport": "#009d48",
    "--app-background-rapport-header-border": "#149449",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#149449",
    "--app-color-top-race-CN": "#149449",
    "--app-color-top-race-time": "#b9b8ba",
    "--app-color-top-race-time-up": "#F9A90E",
    "--app-background-top-race": "#e6e6e6",
    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#77777a",
    "--app-background-meeting-link": "#fff",
    "--app-color-meeting-link-selected": "#fff",
    "--app-background-meeting-link-selected": "#149449",
    "--app-background-meeting-link-borders": "#149449",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#77777a",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "#77777a",
    "--app-color-table-header-border-left": "#149449",
    "--app-background-wrap-race": "#fff",
    "--app-color-table-headings": "",
    "--app-color-table": "#77777a",
    "--app-color-table-subdetails": "#77777a",
    "--app-color-table-numbers": "#77777a",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#77777a",
    "--app-background-table-open": "#5d5d5d",
    //-------------------------  registration-----------------------------
    "--app-color-registration": "#fff",
    "--app-background-registration": "#149449",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#fff",
    "--app-color-registration-validation-error": "#F9A90E",

    //-------------------------  footer-----------------------------
    "--app-color-footer-heading-1": "#00ff75",
    "--app-color-footer-body": "",
    // reset password
    "--app-color-resetpw-header": "#149449",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#009d48",
    "--app-background-profile-body": "#149449",
    "--app-color-transaction-tab-secondary": "#f8bd31",
    "--app-color-transaction-tab-primary": "#fff",
    "--app-color-profile-section-activeheader": "#f8bd31",
    "--app-background-profile-header": "#149449",
    "--app-background-profile-header-border": "#009d48",
    "--app-background-profile-section-header": "#176334",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#fff",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#149449",
    "--app-color-profile-input-border": "#176334",
    "--app-color-profile-input-lable": "#64c388",
    "--app-background-transactions-body": "#149449",
    "--app-color-transactions-header": "#64c388",
    "--app-color-transactions-header-input-border": "#64c388",
    "--app-color-transactions-header-input-lable": "#64c388",
    "--app-color-transactions-header-input": "#fff",

    "--app-background-transactions-tabs": "#149449",
    "--app-color-accounts": "#dedede",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#009d48",
    "--app-background-active-trans-method":
      "linear-gradient(90deg  , #F9A90E -1.5%, #009d48 101.5%)",
    "--app-color-methods-border-left": "#009d48",
    "--app-color-border-acc-input-focus": "#009d48",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#149449",
    "--app-background-betSummary": "#F9A90E",
    "--app-color-betSummary": "#403939",
    "--app-color-yello-odd": "#F9A90E",
    "--app-color-background-dropdown": "#149449",
    "--app-color-betslip": "#149449",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#037b3a",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#F9A90E",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#149449",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#149449",
    "--app-color-button-secondary": "#fff",
    "--app-background-button-secondary": "#fa7f22",
    "--app-color-bet-button": "#5d5d5d",
    "--app-background-bet-button": "#F9A90E",
    "--app-color-rapport-button": "#77777a",
    "--app-background-rapport-button-border": "#b9b8ba",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#b9b8ba",
    "--app-color-coef-button": "#fff",
    "--app-background-coef-button": "#F9A90E",
    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#149449",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#009d48",

    // -----------------------------faq -----------------------------------
    "--app-color-faq-header": "#009d48",

    // -----------------------------default imgs --------------------------
    "--img-default-promo": lonaniDefaults.promoImg,
  },
  partnerID:"PMU",
  country: "NE",
  contact:{
    tel: '+227 96 98 32 30',
    email: 'lonani@lonanionline.ne',
    fax: '+227 20 73 69 11',
    addresse: 'e'
  },
};

export const pmug: Theme = {
  name: "pmug",
  useGateway: false,
  properties: {
    //-------------------------  header-----------------------------
    "--app-background-base": "#fff",
    "--app-background-header": "#fff",
    "--app-background-body": "#e6e6e6",
    "--app-background-turf-home": "#e6e6e6",
    "--app-background-footer": "#bbbaba",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#E61780",
    "--app-background-nav-hover": "#ff05a7c4",
    "--app-background-nav-border-bottom": "#d40c71",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#77777a",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#9ACC09",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#E61780",
    "--app-background-primary-tint": "",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#E61780",

    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#7fc31c",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-background-cmtJr": "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-color-cmtJr": "#fff",

    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions":
      "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-background-border-promotions":
      "linear-gradient(to right, #588a11, #7fc41c)",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#5d5d5d",
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#E61780",
    "--app-background-second-nav-hover": "#E61780",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#E61780",
    "--app-background-border-second-nav-selected": "#E61780",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#e61780",
    "--app-background-rapport": "#E61780",
    "--app-background-rapport-header-border": "#9ACC09",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#5d5d5d",
    "--app-color-top-race-CN": "#5d5d5d",
    "--app-color-top-race-time": "#bbbbbb",
    "--app-color-top-race-time-up": "#5d5d5d",
    // "--app-background-top-race":"linear-gradient(0deg, #a20957, #e61780)",
    "--app-background-top-race": "#fff",

    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#8e8d8d",
    "--app-background-meeting-link": "#e6e6e6",
    "--app-color-meeting-link-selected": "#E61780",
    "--app-background-meeting-link-selected": "#ffff",
    "--app-background-meeting-link-borders": "#fa7f22",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#5d5d5d",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "",
    "--app-color-table-header-border-left": "#dad8d8",
    "--app-color-table-headings": "",
    "--app-color-table": "#5d5d5d",
    "--app-color-table-subdetails": "#5d5d5d",
    "--app-color-table-numbers": "#5d5d5d",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#5d5d5d",
    "--app-background-table-open": "#E61780",
    "--app-background-wrap-race": "#fff",
    //-------------------------  race details-----------------------------
    "--app-color-registration": "#5d5d5d",
    "--app-background-registration": "#bbbaba",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#969292",
    "--app-color-registration-validation-error": "#E61780",

    //-------------------------  footer-----------------------------
    "--app-color-footer-heading-1": "#00ff75",
    "--app-color-footer-body": "",
    // reset password
    "--app-color-resetpw-header": "#bbbaba",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#fff",
    "--app-background-profile-body": "#bbbaba",
    "--app-background-profile-header": "#E61780",
    "--app-background-profile-header-border": "#fff",
    "--app-background-profile-section-header": "#E61780",
    "--app-color-profile-section-activeheader": "#fff",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#5d5d5d",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#e6e6e6",
    "--app-color-profile-input-border": "#bbbaba",
    "--app-color-profile-input-lable": "#5d5d5d",
    "--app-background-transactions-body": "#bbbaba",
    "--app-color-transactions-header": "#5d5d5d",
    "--app-color-transactions-header-input-border": "#fff",
    "--app-color-transactions-header-input-lable": "#fff",
    "--app-color-transactions-header-input": "#fff",
    "--app-color-transaction-tab-secondary": "#628207",
    "--app-color-transaction-tab-primary": "#5d5d5d",
    "--app-background-transactions-tabs": "#bbbaba",
    "--app-color-accounts": "#5d5d5d",
    "--app-color-accounts-header": "#fff",
    "--app-color-accounts-item-border": "#77777a",
    "--app-color-accounts-item": "#5d5d5d",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#a1a1a2",
    "--app-background-active-trans-method":
      "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-color-methods-border-left": "#a1a1a2",
    "--app-color-border-acc-input-focus": "#a1a1a2",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#E61780",
    "--app-background-betSummary": "#9ACC09",
    "--app-color-betSummary": "#fff",
    "--app-color-yello-odd": "#9ACC09",
    "--app-color-background-dropdown": "#e61780",
    "--app-color-betslip": "#77777a",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#e61780",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#9ACC09",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#77777a",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#E61780",
    "--app-color-button-secondary": "#fff",
    "--app-color-button-secondary-border": "#E61780",
    "--app-background-button-secondary": "#E61780",
    "--app-color-button-secondary-invert": "#e61780",
    "--app-color-button-secondary-border-invert": "#e61780",
    "--app-background-button-secondary-invert": "#fff",
    "--app-color-bet-button": "#fff",
    "--app-background-bet-button": "#e61780",
    "--app-color-rapport-button": "#e61780",
    "--app-background-rapport-button-border": "#e61780",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#e61780",

    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#E61780",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#176334",
    // ------------------------ footer -----------------------------------
    // ------------------------faq ---------------------------------------
    "--app-color-faq-header": "#5d5d5d",
    "--app-color-footer-icon": "#777",

    // ------------------------- default images
    "--img-default-promo": pmugDefaults.promoImg,
  },
  country: "GA",
  contact:{
    tel: '+225 07 07 07 18 89',
    email: 'hotline@lonaci.ci',
    fax: '+225 01 01 51 44 00',
    addresse: 'e'
  },
  whiteIcon: true,
};

export const pmuc: Theme = {
  name: "pmuc",
  useGateway: false,
  properties: {
    //-------------------------  header-----------------------------
    "--app-background-base": "#fff",
    "--app-background-header": "#fff",
    "--app-background-body": "#e6e6e6",
    "--app-background-turf-home": "#e6e6e6",
    "--app-background-footer": "#bbbaba",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#e6177f",
    "--app-background-nav-hover": "#ff05a7c4",
    "--app-background-nav-border-bottom": "#d40c71",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#77777a",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#9ACC09",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#e6177f",
    "--app-background-primary-tint": "",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#e6177f",

    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#7fc31c",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "linear-gradient(to right, #ffaa00, #fac04b)",
    "--app-background-cmtJr": "linear-gradient(to right, #17a2b8, #4fdbf1)",
    "--app-color-cmtJr": "#fff",

    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions":
      "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-background-border-promotions":
      "linear-gradient(to right, #588a11, #7fc41c)",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#5d5d5d",
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#e6177f",
    "--app-background-second-nav-hover": "#e6177f",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#e6177f",
    "--app-background-border-second-nav-selected": "#e6177f",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#e6177f",
    "--app-background-rapport": "#e6177f",
    "--app-background-rapport-header-border": "#9ACC09",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#5d5d5d",
    "--app-color-top-race-CN": "#5d5d5d",
    "--app-color-top-race-time": "#bbbbbb",
    "--app-color-top-race-time-up": "#5d5d5d",
    // "--app-background-top-race":"linear-gradient(0deg, #a20957, #e6177f)",
    "--app-background-top-race": "#fff",

    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#8e8d8d",
    "--app-background-meeting-link": "#e6e6e6",
    "--app-color-meeting-link-selected": "#e6177f",
    "--app-background-meeting-link-selected": "#ffff",
    "--app-background-meeting-link-borders": "#fa7f22",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#5d5d5d",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "",
    "--app-color-table-header-border-left": "#dad8d8",
    "--app-color-table-headings": "",
    "--app-color-table": "#5d5d5d",
    "--app-color-table-subdetails": "#5d5d5d",
    "--app-color-table-numbers": "#5d5d5d",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#5d5d5d",
    "--app-background-table-open": "#e6177f",
    "--app-background-wrap-race": "#fff",
    //-------------------------  race details-----------------------------
    "--app-color-registration": "#5d5d5d",
    "--app-background-registration": "#bbbaba",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#969292",
    "--app-color-registration-validation-error": "#e6177f",

    //-------------------------  footer-----------------------------
    "--app-color-footer-heading-1": "#00ff75",
    "--app-color-footer-body": "",
    // reset password
    "--app-color-resetpw-header": "#bbbaba",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#fff",
    "--app-background-profile-body": "#bbbaba",
    "--app-background-profile-header": "#e6177f",
    "--app-background-profile-header-border": "#fff",
    "--app-background-profile-section-header": "#e6177f",
    "--app-color-profile-section-activeheader": "#fff",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#5d5d5d",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#e6e6e6",
    "--app-color-profile-input-border": "#bbbaba",
    "--app-color-profile-input-lable": "#5d5d5d",
    "--app-background-transactions-body": "#bbbaba",
    "--app-color-transactions-header": "#5d5d5d",
    "--app-color-transactions-header-input-border": "#fff",
    "--app-color-transactions-header-input-lable": "#fff",
    "--app-color-transactions-header-input": "#fff",
    "--app-color-transaction-tab-secondary": "#628207",
    "--app-color-transaction-tab-primary": "#5d5d5d",
    "--app-background-transactions-tabs": "#bbbaba",
    "--app-color-accounts": "#5d5d5d",
    "--app-color-accounts-header": "#fff",
    "--app-color-accounts-item-border": "#77777a",
    "--app-color-accounts-item": "#5d5d5d",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#a1a1a2",
    "--app-background-active-trans-method":
      "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-color-methods-border-left": "#a1a1a2",
    "--app-color-border-acc-input-focus": "#a1a1a2",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#e6177f",
    "--app-background-betSummary": "#9ACC09",
    "--app-color-betSummary": "#fff",
    "--app-color-yello-odd": "#9ACC09",
    "--app-color-background-dropdown": "#e6177f",
    "--app-color-betslip": "#77777a",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#e6177f",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#9ACC09",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#77777a",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#e6177f",
    "--app-color-button-secondary": "#fff",
    "--app-color-button-secondary-border": "#e6177f",
    "--app-background-button-secondary": "#e6177f",
    "--app-color-button-secondary-invert": "#e6177f",
    "--app-color-button-secondary-border-invert": "#e6177f",
    "--app-background-button-secondary-invert": "#fff",
    "--app-color-bet-button": "#fff",
    "--app-background-bet-button": "#e6177f",
    "--app-background-bet-button-tint": "#5b8e12",
    "--app-color-rapport-button": "#e6177f",
    "--app-background-rapport-button-border": "#e6177f",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#e6177f",

    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#e6177f",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#176334",
    // ------------------------ footer -----------------------------------
    // ------------------------faq ---------------------------------------
    "--app-color-faq-header": "#5d5d5d",
    "--app-color-footer-icon": "#777",

    // ------------------------- default images
    "--img-default-promo": pmucDefaults.promoImg,
  },
  country: "CM",
  contact:{
    tel: '+225 07 07 07 18 89',
    email: 'hotline@lonaci.ci',
    fax: '+225 01 01 51 44 00',
    addresse: 'e'
  },
  whiteIcon: true,
};

export const lonaci: Theme = {
  name: "lonaci",
  useGateway: true,
  properties: {
    //-------------------------  header-----------------------------
    "--app-background-base": "#fff",
    "--app-background-header": "#fff",
    "--app-background-body": "#009d48",
    "--app-background-footer": "#037b3a",
    "--app-background-turf-home": "#f2f2f2",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#009d48",
    "--app-background-nav-hover": "#037b3a",
    "--app-background-nav-border-bottom": "#eb862c",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#176334",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#eb862c",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#009d48",
    "--app-background-primary-tint": "#009d48",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#eb862c",


    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#037b3a",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "#5d5d5d",
    "--app-background-cmtJr": "#5d5d5d",
    "--app-color-cmtJr": "#fff",
    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions": "#009d48",
    "--app-background-border-promotions": "#009d48",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#5d5d5d",
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#eb862c",
    "--app-background-second-nav-hover": "#009d48",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#037b3a",
    "--app-background-border-second-nav-selected": "#eb862c",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#037b3a",
    "--app-background-rapport": "#009d48",
    "--app-background-rapport-header-border": "#eb862c",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#027835",
    "--app-color-top-race-CN": "#027835",
    "--app-color-top-race-time": "#b9b8ba",
    "--app-color-top-race-time-up": "#eb862c",
    "--app-background-top-race": "transparent",
    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#037b3a",
    "--app-background-meeting-link": "#fff",
    "--app-color-meeting-link-selected": "fff",
    "--app-background-meeting-link-selected": "#eb862c",
    "--app-background-meeting-link-borders": "#fa7f22",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#5d5d5d",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "",
    "--app-background-wrap-race": "#fff",
    "--app-color-table-header-border-left": "#eb862c",
    "--app-color-table-headings": "",
    "--app-color-table": "#037b3a",
    "--app-color-table-subdetails": "#037b3a",
    "--app-color-table-numbers": "#037b3a",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#037b3a",
    "--app-background-table-open": "#5d5d5d",
    //-------------------------  race details-----------------------------
    "--app-color-registration": "#fff",
    "--app-background-registration": "#027835",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#fff",
    "--app-color-registration-validation-error": "#eb862c",

    //-------------------------  footer-----------------------------
    "--app-background-footer-pages": "#037b3a",
    "--app-background-hr": "#037b3a",
    "--app-color-footer-heading-1": "#00ff75",
    "--app-color-footer-body": "",

    // reset password
    "--app-color-resetpw-header": "#027835",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#009d48",
    "--app-background-profile-body": "#027835",
    "--app-background-profile-header": "#027835",
    "--app-background-profile-header-border": "#009d48",
    "--app-background-profile-section-header": "#176334",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#fff",
    "--app-color-transaction-tab-secondary": "#f8bd31",
    "--app-color-transaction-tab-primary": "#fff",
    "--app-color-profile-section-activeheader": "#f8bd31",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#027835",
    "--app-color-profile-input-border": "#176334",
    "--app-color-profile-input-lable": "#64c388",
    "--app-background-transactions-body": "#027835",
    "--app-color-transactions-header": "#64c388",
    "--app-color-transactions-header-input-border": "#64c388",
    "--app-color-transactions-header-input-lable": "#64c388",
    "--app-color-transactions-header-input": "#fff",

    "--app-background-transactions-tabs": "#027835",
    "--app-color-accounts": "#dedede",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#009d48",
    "--app-background-active-trans-method":
      "linear-gradient(90deg  , #eb862c -1.5%, #009d48 101.5%)",
    "--app-color-methods-border-left": "#009d48",
    "--app-color-border-acc-input-focus": "#009d48",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#009d48",
    "--app-background-betSummary": "#eb862c",
    "--app-color-betSummary": "#fff",
    "--app-color-yello-odd": "#eb862c",
    "--app-color-background-dropdown": "#027835",
    "--app-color-betslip": "#027835",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#037b3a",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#eb862c",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#027835",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#009d48",
    "--app-color-button-secondary": "#fff",
    "--app-background-button-secondary": "#eb862c",
    "--app-color-bet-button": "#fff",
    "--app-background-bet-button": "#5d5d5d",
    "--app-color-rapport-button": "#eb862c",
    "--app-background-rapport-button-border": "#5d5d5d",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#5d5d5d",

    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#009d48",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#176334",

    // -----------------------------faq -----------------------------------
    "--app-color-faq-header": "#009d48",

    // ------------------------- default images
    "--img-default-promo": pmucDefaults.promoImg,
  },
  partnerID:"PMU",
  country: "CI",
  contact:{
    tel: '+225 07 07 07 18 89',
    email: 'hotline@lonaci.ci',
    fax: '+225 01 01 51 44 00',
    addresse: 'e'
  },
  whiteIcon: false,
};
export const tunisia: Theme = {
  name: "tunisia",
  useGateway: true,
  properties: {
    //-------------------------  header-----------------------------
    "--app-background-base": "#fff",
    "--app-background-header": "#fff",
    "--app-background-body": "#e6e6e6",
    "--app-background-turf-home": "#e6e6e6",
    "--app-background-footer": "#bbbaba",
    "--app-background-left-menu": "#037b3a",
    "--app-background-right-menu": "",
    "--app-background-nav": "#E61780",
    "--app-background-nav-hover": "#ff05a7c4",
    "--app-background-nav-border-bottom": "#d40c71",
    "--app-background-main": "rgb(242, 242, 242)",
    "--app-background-home": "#037b3a",
    "--app-color-tournament": "#154e2b",
    "--app-color-heading": "#77777a",
    "--app-color-header": "#77777a",
    "--app-color-body": "#5d5d5d",
    "--app-color-footer": "#9ACC09",
    "--app-color-nav": "#fff",
    "--app-color-main": "#212121",
    "--app-color-forgot_pw": "#E61780",
    "--app-background-primary-tint": "",
    "--app-background-input-primary": "#dedede",
    "--app-background-secondary-tint": "#E61780",

    //-------------------------  owl calendar-----------------------------
    "--app-background-calendar-header": "#7fc31c",
    //-------------------------  side menu-----------------------------
    "--app-background-side-menu": "#e6e6e6",
    "--app-color-side-menu": "#fff",
    // lexique
    "--app-color-lexique": "#fff",
    "--app-background-lexique": "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-background-cmtJr": "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-color-cmtJr": "#fff",

    // promotions
    "--app-color-promotions": "#fff",
    "--app-background-promotions":
      "linear-gradient(to right, #588a11, #7fc41c )",
    "--app-background-border-promotions":
      "linear-gradient(to right, #588a11, #7fc41c)",

    // cagnottes
    "--app-color-cagnotte-header": "#fff",
    "--app-background-cagnotte-header": "#5d5d5d",
    //-------------------------  second nav-----------------------------
    "--app-background-second-nav": "#E61780",
    "--app-background-second-nav-hover": "#E61780",
    "--app-color-second-nav": "#fff",
    "--app-background-second-nav-selected": "#fff",
    "--app-color-second-nav-selected": "#E61780",
    "--app-background-border-second-nav-selected": "#E61780",
    "--app-color-second-nav-course": "#77777a",

    //-------------------------  rapports-----------------------------
    "--app-color-rapport": "#e61780",
    "--app-background-rapport": "#E61780",
    "--app-background-rapport-header-border": "#9ACC09",

    //-------------------------  top races-----------------------------
    "--app-color-top-race-RN": "#5d5d5d",
    "--app-color-top-race-CN": "#5d5d5d",
    "--app-color-top-race-time": "#bbbbbb",
    "--app-color-top-race-time-up": "#5d5d5d",
    // "--app-background-top-race":"linear-gradient(0deg, #a20957, #e61780)",
    "--app-background-top-race": "#fff",

    //-------------------------  meetings-----------------------------
    "--app-color-meeting-link": "#8e8d8d",
    "--app-background-meeting-link": "#e6e6e6",
    "--app-color-meeting-link-selected": "#E61780",
    "--app-background-meeting-link-selected": "#ffff",
    "--app-background-meeting-link-borders": "#fa7f22",

    //-------------------------  race details-----------------------------
    "--app-color-race-time": "#5d5d5d",
    //-------------------------  race list-----------------------------
    "--app-color-table-header": "",
    "--app-color-table-header-border-left": "#dad8d8",
    "--app-color-table-headings": "",
    "--app-color-table": "#5d5d5d",
    "--app-color-table-subdetails": "#5d5d5d",
    "--app-color-table-numbers": "#5d5d5d",
    "--app-color-table-open": "#fff",
    "--app-color-table-content-open": "#5d5d5d",
    "--app-background-table-open": "#E61780",
    "--app-background-wrap-race": "#fff",
    //-------------------------  race details-----------------------------
    "--app-color-registration": "#5d5d5d",
    "--app-background-registration": "#bbbaba",
    "--app-background-registration-input": "#dedede",
    "--app-color-registration-input": "#000",
    "--app-background-registration-input-border": "#969292",
    "--app-color-registration-validation-error": "#E61780",

    //-------------------------  footer-----------------------------
    "--app-background-footer-pages": "#e6e6e6",
    // reset password
    "--app-color-resetpw-header": "#bbbaba",
    //-------------------------  transactions history and account -----------------------------
    "--app-background-transactions": "#fff",
    "--app-background-profile-body": "#bbbaba",
    "--app-background-profile-header": "#E61780",
    "--app-background-profile-header-border": "#fff",
    "--app-background-profile-section-header": "#E61780",
    "--app-color-profile-section-activeheader": "#fff",
    "--app-color-profile-section-header": "#fff",
    "--app-color-profile-input": "#5d5d5d",
    "--app-color-profile-input-placeholder": "#eee",
    "--app-background-profile-input": "#e6e6e6",
    "--app-color-profile-input-border": "#bbbaba",
    "--app-color-profile-input-lable": "#5d5d5d",
    "--app-background-transactions-body": "#bbbaba",
    "--app-color-transactions-header": "#5d5d5d",
    "--app-color-transactions-header-input-border": "#fff",
    "--app-color-transactions-header-input-lable": "#fff",
    "--app-color-transactions-header-input": "#fff",
    "--app-color-transaction-tab-secondary": "#628207",
    "--app-color-transaction-tab-primary": "#5d5d5d",
    "--app-background-transactions-tabs": "#bbbaba",
    "--app-color-accounts": "#5d5d5d",
    "--app-color-accounts-header": "#fff",
    "--app-color-accounts-item-border": "#77777a",
    "--app-color-accounts-item": "#5d5d5d",
    "--app-background-row-even": "#fff",
    "--app-background-row-odd": "#dedede",
    "--app-color-transactions-container-color": "#154e2b",
    "--app-background-transaction-methods": "#a1a1a2",
    "--app-background-active-trans-method":
      "linear-gradient(to right, #588a11, #7fc41c)",
    "--app-color-methods-border-left": "#a1a1a2",
    "--app-color-border-acc-input-focus": "#a1a1a2",

    //-------------------------  betslip-----------------------------
    "--app-color-betslip-header": "#fff",
    "--app-background-betslip-header": "#E61780",
    "--app-background-betSummary": "#9ACC09",
    "--app-color-betSummary": "#fff",
    "--app-color-yello-odd": "#9ACC09",
    "--app-color-background-dropdown": "#e61780",
    "--app-color-betslip": "#77777a",
    "--app-background-betslip": "#fff",
    "--app-color-betslip-amount": "#e61780",
    "--app-color-betslip-meeting": "#777777",
    //-------------------------  betslip-----------------------------
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",

    "--app-background-checkbox-input": "#9ACC09",
    "--app-color-checkbox-input-border": "#fff",
    //-------------------------  account modal -----------------------------

    "--app-background-modal-window": "#77777a",
    "--app-color-modal-window": "#fff",

    //-------------------------  buttons-----------------------------
    "--app-color-button-primary": "#fff",
    "--app-background-button-primary": "#E61780",
    "--app-color-button-secondary": "#fff",
    "--app-color-button-secondary-border": "#E61780",
    "--app-background-button-secondary": "#E61780",
    "--app-color-button-secondary-invert": "#e61780",
    "--app-color-button-secondary-border-invert": "#e61780",
    "--app-background-button-secondary-invert": "#fff",
    "--app-color-bet-button": "#fff",
    "--app-background-bet-button": "#e61780",
    "--app-color-rapport-button": "#e61780",
    "--app-background-rapport-button-border": "#e61780",
    "--app-background-rapport-button": "#fff",
    "--app-color-betSlip-button": "#fff",
    "--app-background-betSlip-button": "#e61780",

    //-------------------------  Account Modal-----------------------------
    "--app-background-main-modal": "#E61780",
    "--app-color-main-modal": "#efd7c2",
    "--app-color-main-modal-border": "#176334",
    // ------------------------ footer -----------------------------------
    "--app-color-footer-heading-1": "#77777a",
    "--app-color-footer-body": "#5d5d5d",
    // ------------------------faq ---------------------------------------
    "--app-color-faq-header": "#5d5d5d",
    "--app-color-footer-icon": "#777",

    // ------------------------- default images
    "--img-default-promo": tunisiaDefaults.promoImg,
  },
  country: "TU",
  contact:{
    tel: '+225 07 07 07 18 89',
    email: 'hotline@lonaci.ci',
    fax: '+225 01 01 51 44 00',
    addresse: 'e'
  },
  whiteIcon: true,
};
