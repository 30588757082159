import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  constructor() {

  }
  transform(value: any) {
    if (value !== undefined) {
      value = value + '';
      return this.initials(value);
    }
  }
  initials(name: string): any {
    let nameArr = name.split(' ');
    let nameF = '';
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < nameArr.length; i++) {
      nameF = nameF.trim().length === 0 ? nameArr[i].charAt(0) : nameF + '.' + nameArr[i].charAt(0);
    }
    return nameF + '.';
  }
}
