import { WebsocketService } from 'src/app/providers/WebsocketService';
import { UserManagerService } from './../userManager/user-manager.service';
import { HttpService } from './../../../wrappers/Http';
import { Injectable, HostListener } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { StorageService } from '../../storage.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../modal-service/ModalService';
import { format } from 'url';
import { Partants } from './../../../interfaces/betObject';
import { promise } from 'protractor';
import { ConfigService } from '../../config/config.service';

export interface turfBetObject {
  value?: number;
  race?: {
    name?: any;
    id?: any;
    number?: any;
    partants?: number;
  };
  reunion?: {
    name?: any;
    id?: any;
    number?: any;
  };
  chavalBase?: Array<any>;
  chevalAssocie?: Array<any>;
  coef?: number;
  coefMax?: number;
  miseDeBase?: number;
  ordre?: boolean;
  game?: any;
  combinations?: number;
  gameType?: any;
  baseisBlocked?: boolean;
  betAmount?: number;
  compl?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TurfServiceService {
  public requireConfirmation: boolean;
  private _meetingId;
  private _raceId;
  private _raceReport: any;
  // public reportParticipants: any;
  public betObject: any;
  public selectedMeeting: any;
  public reportResults: any = [];
  public racesInMeeting: any;
  public eventDate = new Date();
  public betsArray: Array<turfBetObject> = [];
  public selectedHorses: Set<string> = new Set();
  activeRaceInSelectedMeeting: any;
  partantsInactiveRace: any = [];
  activeGameObject: any;
  checkBoxConfig: any;
  casaqueCourse: any;
  onBetOptionSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  courseChange$: Subject<any> = new Subject();
  activeReport: any;
  activeReportNonPartant: any;
  reportPartants: any = [];
  reportRap: any = [];
  nonPartant: any;
  report: any;
  spotGametype: boolean;
  betLoader = false;

  activeRaceDetail: any;
  activeCombinationBtn: any;
  activeBets: Array<turfBetObject> = [];
  reportLoader: boolean;
  onViewReportClickEvent$: Subject<{}> = new Subject();
  onActiveRaceClickEvent$: Subject<{}> = new Subject();
  amountTotal: number;
  betAmount: number = 0;
  combinations: any;
  combinationsValue: number;
  activeCombinations: number;
  disbld: boolean;
  alert: boolean;
  spotIcon: any;
  scoltop: string;
  national: any;
  private savebetParam: {
    sid?: any;
    number: any;
    ref: string;
    internal_ref?: string;
    channel: number;
    format: string;
    pwd: string;
    userid: any;
    str_From: any;
    mtv?: number;
    uid?: string;
  };
  toutOdre: any = false;
  cagnoteGames: Array<any> = [];
  message: any;
  partants: any;
  rawActiveGames: Promise<any>;
  load: boolean;
  games: any;
  maxSelections = false;
  constructor(
    private httpHandler: HttpService,
    public userService: UserManagerService,
    public storageService: StorageService,
    private translate: TranslateService,
    private modalService: ModalService,
    private configService: ConfigService

  ) {
    //  console.log( 'event Date' , this.eventDate);
    //  let me = this
    // this.websocket.getCagnote.subscribe((data)=>{
    //   if(data.length > 0){
    //     me.cagnoteGames = data;
    //     console.log('cagnots', me.cagnoteGames)
    //   }
    //   me.cagnoteGames= []
    // })
  }
  racereport() {
    const me = this;
    me.onViewReportClickEvent$.subscribe((report) => {
      if (report.hasOwnProperty('id')) {
        me.report = report;
        // console.log(report, 'race-report')
        this.nonPartant = this.report.nonPartant;
        // return this.nonPartant;
      } else {
        me.report = [];
      }
    });
  }

  helloTurf() {
    // console.log("hello from truf service");
  }

  public get getMeetingId() {
    return this._meetingId;
  }

  public set setMeetingId(value) {
    this._meetingId = value;
  }

  public get getRaceId() {
    return this._raceId;
  }

  public set setRaceId(value) {
    this._raceId = value;
  }

  public get getraceReport() {
    return this._raceReport;
  }

  public set setraceReport(value) {
    this._raceReport = value;
  }

  onMeetingSelect(meeting: any) {
    if (this.selectedMeeting == meeting) {
      // this.selectedMeeting = undefined;
      // this.racesInMeeting =undefined;
      return;
      // this.storageService.deleteItem("selectedMeeting");
    } else {
      this.selectedMeeting = meeting;
      this.racesInMeeting = meeting.races;

      this.storageService.save('selectedMeeting', meeting);
    }
  }

  formatHeader(eventDate?) {
    // console.log("event date" , this.eventDate)
    moment.locale(this.translate.currentLang);

    const today = eventDate
      ? moment(eventDate).format('LLLL')
      : moment().format('LLLL');

    const todayArr = today.split(' ');

    return (
      todayArr[0] + ' ' + todayArr[1] + ' ' + todayArr[2] + ' ' + todayArr[3]
    );
  }

  formatDate(date) {
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    // console.log(date);
    let day = date ? new Date().getDate() : date.getDate().toString();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    day = day + '';
    day = day.trim().length == 1 ? '0' + day : day;

    return day + '/' + monthNames[monthIndex] + '/' + year;
  }

  casaqueCourseUrl(cNumber?: any, rNumber?: any) {
    let raceNumber = cNumber;
    let meetingNumber = rNumber;
    if (!rNumber) {
      const meetingObj = this.selectedMeeting
        ? this.selectedMeeting
        : this.storageService.getObject('selectedMeeting', {});
      meetingNumber = meetingObj.nReunion;
    }
    if (!cNumber && this.activeRaceInSelectedMeeting) {
      raceNumber = this.activeRaceInSelectedMeeting.course_number;
    }

    return (
      this.formatDate(this.eventDate).replace(/[/]/g, '') +
      '/R' +
      meetingNumber +
      '/C' +
      raceNumber +
      '/P'
    );
  }

  onRaceSelect(race: any, national?: any) {
    this.national = '';
    this.activeRaceDetail = national ? 'national' : '';
    if (!$('#race-' + race.numero + national).hasClass('open')) {
      // close all open details
      $('[id^=\'race-\']').removeClass('open');
      $('[id^=\'race-details-\']').css('display', 'none');

      // close all report details
      $('[id^=\'race-\']').removeClass('open');
      $('[id^=\'report-\']').css('display', 'none');

      this.initRaceDetailsComponentData(race);

      $('#race-' + race.numero + national).addClass('open');

      // show race details view
      $('#race-details-' + race.numero + national).css('display', '');
      this.scoltop = 'race-' + race.numero;
      this.national = national;
      // this.onWindowScroll("#race-" + race.numero + national)
    } else {
      $('#race-' + race.numero + national).removeClass('open');
      this.scoltop = '';
      // hide race details view
      $('#race-details-' + race.numero + national).css('display', 'none');
    }
  }

  initRaceDetailsComponentData(race) {
    this.activeCombinationBtn = 1;
    this.activeRaceInSelectedMeeting = race;
    this.partantsInactiveRace = race.partants;
    // this.onGameTypeSelect(race.jeu.split("@")[0]);
    this.casaqueCourse = this.casaqueCourseUrl(race.course_number);
  }

  showSpotIcon() {
    let moveout = false;
    // console.log(this.activeRaceInSelectedMeeting)
    if (!this.activeRaceInSelectedMeeting.jeu) {
      return
    }
    for (const game of this.activeRaceInSelectedMeeting.jeu.split('@')) {
      if (game.includes('|SI, CE')) {
        this.spotIcon = true;
        // console.log("show Spot")
        moveout = true;
        break;
      }
      if (moveout) {
        break;
      }
    }
  }
  onGameTypeSelect(game) {
    // set active btn to standard
    this.showSpotIcon();
    if (this.spotGametype) {
      this.activeCombinationBtn = 2;
    } else {
      this.activeCombinationBtn = 1;
    }
    this.betAmount = 0;
    const gameInfo = game.split('-');

    this.activeGameObject = {
      icon: gameInfo[0],
      prixdeBase: gameInfo[1],
      coefMax: gameInfo[2],
      numbreCheveauxBase: Number(gameInfo[3]),
      formuleDeJeur: gameInfo[4].split(','),
      color: gameInfo[5],
      touteOdre: gameInfo[6],
      flexi: gameInfo[7],
      spot: gameInfo[8],
      spotChevalLength: Number(gameInfo[8].split('|')[0]),
    };
    // console.log("checking for spot games", this.activeGameObject);

    let checkBoxConfig;

    if (this.activeGameObject.formuleDeJeur.length < 2) {
      checkBoxConfig = {
        name: this.gameTypeToName(this.activeGameObject.icon),
        nbtn: 1,
        stand: true,
        hasChamp: false,
      };
    } else if (this.activeGameObject.formuleDeJeur.length > 2) {
      checkBoxConfig = {
        name: this.gameTypeToName(this.activeGameObject.icon),
        nbtn: 2,
        stand: true,
        champ: true,
        hasChamp: true,
      };
    } else {
      checkBoxConfig = {
        name: this.gameTypeToName(this.activeGameObject.icon),
        nbtn: 2,
        stand: true,
        champ: true,
        hasChamp: true,
      };
    }

    this.activeGameObject.check = checkBoxConfig;
    this.onBetOptionSelected$.next(true);
  }

  gameTypeToName(gameType) {
    let gameName = '';

    switch (gameType) {
      case 'PSG':
        gameName = 'gm_simple_gagnant';
        break;

      case 'PSP':
        gameName = 'gm_simple_place';
        break;

      case 'PCG':
        gameName = 'gm_couple_gagnant';
        break;

      case 'PCP':
        gameName = 'gm_couple_place';
        break;

      case 'P24':
        gameName = 'gm_2_sur_4';
        break;

      case 'PTRIO':
        gameName = 'gm_ptrio';
        break;

      case 'PMM':
        gameName = 'gm_multi';
        break;

      default:
        gameName = gameType;
        break;
    }

    return gameName;
  }

  viewReport(race: any, national?: any) {

    // console.log("national", national)
    this.activeRaceDetail = national ? 'national' : '';
    // console.log(race)
    if (!$('#race-' + race.numero + national).hasClass('open')) {
      $('[id^=\'race-\']').removeClass('open');
      $('[id^=\'report-\']').css('display', 'none');

      // close all open details
      $('[id^=\'race-\']').removeClass('open');
      $('[id^=\'race-details-\']').css('display', 'none');

      // show loader
      $('#report-loader-' + race.numero + national).css('display', '');
      $('#race-' + race.numero + national).addClass('open');

      // show race details view
      $('report-' + race.numero + national).css('display', '');

      this.scoltop = 'race-' + race.numero + national;

      this.getReportData(this.selectedMeeting.numero, race.numero, national);
      this.scoltop = 'race-' + race.numero + national;
      this.national = national;

      // this.onWindowScroll("race-" + race.numero)

      // // console.log('open drop-down');
      // $('#race-' + race.numero).addClass('open');

      // // show report section
      // $('#report-' + race.numero).css('display', '');

      // show close btn
      // $('#closeRaceBt-' + race.numero).css('display', 'block');
    } else {
      this.activeReport = {};
      $('#race-' + race.numero + national).removeClass('open');

      // hide report section
      $('#report-' + race.numero + national).css('display', 'none');
      this.scoltop = '';
      // this.turfServices.onViewReportClickEvent$.next({});
      // $('#closeRaceBt-' + race.numero).css('display', 'none');
    }
  }

  remove(race) {
    this.betsArray = [];
    this.selectedHorses.clear();
    this.betObject = {};
    this.disbld = false;
    this.activeCombinations = 0;
    this.clearCache();
    this.betAmount = 0;
    this.maxSelections = false;
    // this.turfService.activeRaceInSelectedMeeting=[];
    // this.turfService.activeGameObject=[];
    // this.turfService.onRaceSelect(
    //   this.turfService.activeRaceInSelectedMeeting,
    //   ""
    // );
    // this.turfService.onRaceSelect(
    //   this.turfService.activeRaceInSelectedMeeting,
    //   ""
    // );
    // this.turfService.onRaceSelect(race);
  }
  timeConverter(num: number): string {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;

    if (hours > 0 && minutes > 0) {
      return hours + ' hr ' + minutes + ' min';
    } else if (hours > 0 && minutes == 0) {
      return hours + 'hr';
    } else {
      return minutes + ' min';
    }
  }
  initReportComponentData(report) {
    this.activeReport = report;
    // this.reportPartants = this.activeReport['partants'];
    this.reportPartants = this.orderWinningPartants(
      this.activeReport.arrivee,
      this.activeReport.partants
    );

    this.casaqueCourse =
      this.formatDate(new Date(this.eventDate)).replace(/[/]/g, '') +
      '/R' +
      this.activeReport.rNumber +
      '/C' +
      this.activeReport.cOrder +
      '/P';

    this.reportRap = this.formateResultObj(this.activeReport.rap);
  }

  formateResultObj(reportObj) {
    const reportArr = [];

    Object.keys(reportObj).forEach((key) => {
      const obj = reportObj[key];
      obj.key = key;
      reportArr.push(obj);
    });

    return reportArr;
  }

  orderWinningPartants_(partants, arrivee) {
    const orderdPartants = [];
    const arriveeArr = arrivee.split('-');
    for (let i = 0; i < arrivee.length; i++) {
      const arriKey = arrivee[i];
      for (let j = 0; j < partants.length; j++) {
        const partant = partants[j];
        if (arriKey === partant.numero) {
          orderdPartants.push(partant);
        }
      }
    }

    return orderdPartants;
  }

  orderWinningPartants(arrivee, partants) {
    const result = [];
    const order = arrivee.split`-`.map((x) => +x);
    order.forEach(function (key) {
      let found = false;
      partants = partants.filter(function (item) {
        if (!found && item.numero == key) {
          result.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });
    return result;
  }

  ojectToArrOfOject(obj) {
    const arrOfObj = [];

    Object.keys(obj).forEach((key) => { });

    return arrOfObj;
  }

  clearReportComponentValues() {
    this.activeReport = {};
    // this.reportPartants = this.activeReport['partants'];
    this.reportPartants = [];

    this.casaqueCourse = '';

    this.reportRap = [];
  }

  getReportData(meetingId: any, raceId: any, national?: any) {
    const me = this;
    const params = { cid: raceId, rid: meetingId };

    this.clearReportComponentValues();
    me.getRaceReport(params).then((resp) => {
      if (resp.hasOwnProperty('tcourse') && resp.tcourse.length > 0) {
        $('#report-loader-' + raceId + national).css('display', 'none');

        me.initReportComponentData(resp.tcourse[0]);

        // console.log('open drop-down');
        $('#race-' + raceId + national).addClass('open');

        // show report section
        $('#report-' + raceId + national).css('display', '');
      }
    });
  }

  scrollToElement(param, type?) {
    let elementList;
    if (type && type === 'class') {
      elementList = document.querySelectorAll('.' + param);
    } else {
      elementList = document.querySelectorAll('#' + param);
    }
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  // HTTP Requests
  getActiveGames(eventData?): Promise<any> {
    var params: any = {
      channel: 7,
      versioncode: 3,
      date_jr: eventData ? moment(eventData).format('YYYY-MM-DD') : ''
    };

    this.rawActiveGames = this.httpHandler.get_no_auth(
      this.configService.PROXIES.turf_gateway +
      this.configService.TurfEndpoints.getActiveGames,
      params
    );
    return this.rawActiveGames;
  }

  getRaceReport(params): Promise<any> {
    return this.httpHandler.Hget(
      this.configService.PROXIES.turf_gateway,
      this.configService.TurfEndpoints.getRaceReport,
      params
    );
  }
  // userSessionExpired(params): Promise<any> {
  //   return this.httpHandler.Hget(
  //     this.configService.PROXIES.turf_gateway,
  //     this.configService.TurfEndpoints.Rabbit_Address,
  //     params
  //   );
  // }
  calculateNewAmount(data: turfBetObject) {
    if (!Number(data.coef) || !Number(data.coefMax)){
      this.betAmount = 0;
    }else {
      if (!(Number(data.coef) && (Number(data.coef) <= Number(data.coefMax)))) {
        this.alert = true;
      }
      this.alert = false;
      this.betAmount = this.getAmount(data);
      data.value = this.betAmount;
      data.combinations = this.combinationsValue;
    }
    
  }
  clearCache() {
    this.storageService.delete_cookie("betObject")
    this.storageService.delete_cookie("selectedHorses")
    this.storageService.delete_cookie("betsArray")
    this.storageService.delete_cookie("activeRace");
    this.storageService.delete_cookie("activeGameObject");
    this.storageService.delete_cookie("activeObject");
    this.storageService.delete_cookie("activeBets");
    this.storageService.delete_cookie("spotGametype");

  }
  getTotalAmount() {
    const amount = 0;
    // this.betsArray.forEach(bet => {
    //   amount += bet.value
    // })
    return this.betAmount;
  }
  getTotalCombinationsValue() {
    let combinaison = 0;
    if (this.betsArray && this.betsArray.length > 0) {
      // console.log(this.activeBets);

      this.betsArray.forEach((bet) => {
        if (this.activeGameObject.icon == bet.game) {
          combinaison += bet.combinations;
        }
      });
    }
    this.activeCombinations = combinaison;
  }
  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  getAmount(data: turfBetObject): any {

    let partants = this.getNonPartant(this.activeRaceInSelectedMeeting) ? data.race.partants - this.getNonPartant(this.activeRaceInSelectedMeeting).split('-').length : data.race.partants
    // console.log( this.activeGameObject)
    this.combinationsValue = this.combinaisonCount(
      data.gameType,
      this.activeGameObject.touteOdre == '0' ? false : true,
      this.toutOdre,
      ['CT', 'CP'].includes(data.gameType) ? 0 : data.chavalBase.length,
      data.chavalBase.slice(this.activeGameObject.numbreCheveauxBase).length +
      data.chevalAssocie.length,
      partants,
      data.chavalBase
        .slice(0, this.activeGameObject.numbreCheveauxBase)
        .filter((horseNumber) => {
          return horseNumber !== 'X';
        }).length,
      this.activeGameObject.numbreCheveauxBase
    );
    this.amountTotal = this.combinationsValue * data.miseDeBase * data.coef;
    data.value = this.amountTotal;
    data.combinations = this.combinationsValue;
    this.amountTotal = this.amountTotal;
    return this.amountTotal + '';
  }
  arrangement(k, n): any {
    let r = 1;
    for (let i = 0; i < k; i++) {
      r *= n - i;
    }
    return r;
  }
  combinaison(k, n): any {
    let r = 1;
    for (let i = 0; i < k; i++) {
      // if (n != i)
      r = (r * (n - i)) / (i + 1);
    }
    // console.log(r);
    return r;
  }
  combinaisonCount(formule, ordre, toutOrdre, k, p, n, b, s): any {
    // console.log(formule, ordre, toutOrdre, k, p, n, b, s);
    if (!ordre) {
      // console.log("odre false");
      if (formule == 'CE') {
        return this.combinaison(s, k);
      }
      if (formule == 'CP') {
        return this.combinaison(s - b, p);
      }
      if (formule == 'CT') {
        return this.combinaison(s - b, n - b);
      }

    } else {
      if (!toutOrdre) {
        // console.log("odre true ttodre false");
        if (formule == 'CE') {
          return this.combinaison(s, k);
        }
        if (formule == 'CP') {
          return this.arrangement(s - b, p);
        }
        if (formule == 'CT') {
          return this.arrangement(s - b, n - b);
        }
      } else {
        // console.log("odre true ttodre true");
        if (formule == 'CE') {
          return this.arrangement(s, k);
        }
        if (formule == 'CP') {
          return this.arrangement(b, s) * this.arrangement(s - b, p);
        }
        if (formule == 'CT') {
          return this.arrangement(b, s) * this.arrangement(s - b, n - b);
        }
        if (formule == 'SI') {
          return this.arrangement(s, s);
        }
      }
    }
    return 1;
  }
  getNonPartant(race) {
    const nonPartants = [];
    if (race.partants) {
      race.partants.forEach((partant) => {
        if (partant.status !== 'Partant') {
          nonPartants.push(partant.numero);
        }
      });
      return nonPartants.join('-');
    } else {
      return '';
    }
  }

  SaveBet(): Promise<any> {
    //  console.log('game_icon', this.activeGameObject.icon)
    const params = {};
    // this.spotGametype = this.spotGametype ? this.spotGametype : false;
    // console.log(this.activeGameObject);
    // const HorsesArray = Array.from(this.selectedHorses);

    const myHorsesArray = [];


    for (const horse of this.activeBets[0].chavalBase) {
      myHorsesArray.push(horse);
    }
    if(this.betObject.gameType == 'CP' && this.betObject.chevalAssocie.length == 1){
      this.betLoader = false;
      return Promise.reject("ml_compl_horses_not_valid")
    }
    if (this.activeBets[0].chevalAssocie) {
      for (const horse of this.activeBets[0].chevalAssocie) {
        myHorsesArray.push(horse);
      }
    }
    // console.log(myHorsesArray, this.selectedHorses, 'Horse Array');
    if (
      !this.userService.userData ||
      (this.userService.userData && !this.userService.userData.lg_AGENT_ID && !this.userService.userData.customer_ID)
    ) {
      this.requireConfirmation = true;
      //  const offsetT = document.getElementById('turf-betslip').offsetTop;
      //  $('#turf-betslip').css('top', offsetT + 'px');
      //  this.modalService.openDialog('security_alert', 'ml_login_to_bet', false)
      // .then(_ => {
      //     $('#turf-betslip').css('top', '0px');
      //     $('#login1').css('left', '-247px')
      //     $('#login').addClass('show');
      //     $('#login1').addClass('show');
      //     window.scrollTo(0,0)
      //     $("#loginBtn").attr("aria-expanded","true");
      //   });
      this.betLoader = false;
      return Promise.reject("ml_login_to_bet")
    }


    // const authParam = {
    //   sid: this.userService.userData.lg_AGENT_ID
    //     ? this.userService.userData.session
    //     : undefined,
    // };
    // return this.httpHandler
    //   .Post(this.configService.PROXIES.userService + this.configService.EndPoints.sessionverification, authParam)
    //   .then((response) => {

    // if (response.hasOwnProperty("erc") && response.erc + "" === "1") {
    // if (response) {

    let flexi = this.activeGameObject.flexi == '1.0' ? 1 : 0;
    const multi = this.activeGameObject.icon == ('MULTIC3' || 'PM' || 'MM' || 'PMM') ? '1' : '0'

    const odre =
      this.toutOdre
        ? 1
        : 0;
    const spotGametype = this.spotGametype ? '1' : '0';
    // odre = odre ? 1 : 0;
    flexi = 0;
    this.activeGameObject.compl = this.betObject.compl
      ? this.activeGameObject.compl
      : 0;
    let format;
    /*
     if (this.selectedHorses == undefined) {
              format =
               this.activeGameObject.icon +
               '-' +
               this.activeGameObject.touteOdre +
               '-' +
               this.activeGameObject.compl +
               '-' +
               this.betObject.coef +
               '-' +
               flexi +
               '-' +
               spotGametype +
               '-' +
               myHorsesArray.toString();
           } else {
             format =
               this.activeGameObject.icon.split('_')[0] +
               '-' +
               odre +
               '-' +
               this.activeGameObject.compl +
               '-' +
               this.betObject.coef +
               '-' +
               flexi +
               '-' +
               spotGametype +
               '-' +
               myHorsesArray.toString().replace(/,/g, '-');

           }
           */
    if (!this.selectedHorses)
      format = `${this.activeGameObject.icon}-${this.activeGameObject.touteOdre}-${this.activeGameObject.compl}` +
        `-${this.betObject.coef}-${flexi}-${spotGametype}-${multi}-${myHorsesArray.join("-")}`
    else
      format = `${this.activeGameObject.icon.split('_')[0]}-${odre}-${this.activeGameObject.compl}-${this.betObject.coef}` +
        `-${flexi}-${spotGametype}-${multi}-${myHorsesArray.join("-")}`
    this.savebetParam = {
      channel: 3,
      userid: this.userService.userData.customer_ID,
      pwd: this.configService.useGateway ? this.userService.userData.session : `${this.userService.userData.pin}@NO_CRYPT`,
      ref: new Date().getTime().toString(),
      internal_ref: new Date().getTime().toString(),
      str_From: this.userService.userData.phone_number.toString().replace(/ /g, ''),
      format: format.replace(/X/g, '*'),
      mtv: this.activeGameObject.numbreCheveauxBase ? this.activeGameObject.numbreCheveauxBase : undefined,
      number: this.activeRaceInSelectedMeeting.numero,
      sid: this.userService.userData.session,
      uid: this.userService.userData.customer_ID
    };
    /*
       mtv: this.activeGameObject.numbreCheveauxBase ? this.activeGameObject.numbreCheveauxBase : undefined, */
    const resp = this.httpHandler.HttpParamsPost(
      this.configService.PROXIES.save_bet + this.configService.EndPoints.saveBet,
      this.savebetParam
    );

    return resp;

  }
}
