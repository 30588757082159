import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrData } from 'src/app/interfaces/validation';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  email: any;
  phone: any;
  login: any;
  option: any = "EMAIL"
  user: any;
  verificationData: any = {};
  verificationCode= '';
  resendingCode = false;
  show = false;
  errData: ErrData = {}
  timeout: any;
  msgError: boolean = false;
  msgSuccess: boolean = false;
  constructor(public userManagerService: UserManagerService, private storageService: StorageService,
    public modalService: ModalService, private translate: TranslateService, public config : ConfigService) { }

  ngOnInit() {
    // this.user =  true;
  }
  selectOption(option) {
    // console.log(option)
    this.option = option;
  }
  requestActivationCode() {
    this.errData.msg = null;
    this.modalService.msg = null;
    var pattern: RegExp;
    this.msgError = false;
    this.msgSuccess = false;
    switch (this.option) {
      case 'SMS':
        pattern = new RegExp(this.userManagerService.validationPatterns.phone);
        if(!pattern.test(this.phone)) {
          /**
           * additional check for phone number length
           * || this.phone.toString().length < this.config.phoneNumberLength
           */
          this.msgError = true;
          return this.errData['msg'] = "phone_required";
        }
        this.requestOTPsms();
        break;
      case "EMAIL":
        pattern = new RegExp(this.userManagerService.validationPatterns.email);
        if(!pattern.test(this.email)) {
          this.msgError = true;
          return this.errData['msg'] = "email_required";
        }
          this.requestCodeEmail();
        break;
      default:
        break;
    }
  }

  requestCodeEmail(){
    this.userManagerService.isProcessing = true;
    this.userManagerService.resendActivation(this.email).then(response => {
      if (response.hasOwnProperty('erc') && response.erc == '1') {
        this.modalService.destroy();
        this.modalService.isError = false;
        this.modalService.isSuccess = true;
        this.modalService.msg = this.translate.instant("verification_code_email_msg", {email: this.email});
      }
      else if (response.hasOwnProperty('msg') && response.msg !== '') {
       this.errData['msg'] = response.msg;
       this.msgError = true;
      }
      else {
        throw "ml_message_server_error";
      }
      this.userManagerService.isProcessing = false;
    }).catch(data => {

      this.userManagerService.isProcessing = false;
      this.modalService.isError = true;
      this.modalService.isError = true;
      this.modalService.isSuccess = false;
      this.modalService.msg = data ? data :  'connection_error';
      // this.modalService.showModal();

    }).finally(()=>{
      clearTimeout(this.timeout);
      this.timeout = setTimeout(()=>{
        if(this.modalService.msg.length > 0)
          this.modalService.showModal();
      }, 750)
    });
  }

  requestOTPsms() {
    if( Number.isNaN(Number.parseInt(this.phone.toString()))) {
      //  || this.phone.toString().length < this.config.phoneNumberLength
      this.msgError = true;
      return this.errData.msg = "invalid_phone_number";
    }
    let phone = this.config.country_code + '' + this.phone;
    if (this.phone.length < 7) {
      phone = this.userManagerService.userphone;
    }
    var params = { phone, lang: this.translate.getDefaultLang() };
    this.userManagerService.isProcessing = true;
    this.userManagerService.resendSMS(params).then(data => {
      if (data.erc && data.erc == "1") {
        // this.startWaitTimer();
        this.userManagerService.userID = data.data && data.data.lg_AGENT_ID ? data.data.lg_AGENT_ID.toString() : ''
        this.user = true;
        // this.modalService.msg = '';
        this.msgSuccess = true;
        this.errData['msg'] = "check_sms_open_link"
      } else {
        this.msgError = true
        this.errData.msg = data.msg;
      }
    }).finally(() => {
      this.verificationCode = '';
      this.userManagerService.isProcessing = false;
    });
  }
  confirmCode() {
    if (this.verificationCode === '') {
      return;
    }
    const params = {
      agentId: this.userManagerService.userID,
      // lang: navigator.language,
      lang: this.translate.currentLang,
      activationCode: this.verificationCode,
      channel: '3',
      channel_id: 3
    };
    this.modalService.msg = '';
    this.errData.msg = ""
    this.userManagerService.isProcessing = true;
    this.userManagerService.confirmCodeRequest(params).then(response => {
      // this.userManagerService.userData = response;
      // this.userManagerService.buildUserData(response);
      this.verificationCode = '';
      if(response.hasOwnProperty('erc') && response.erc == '1'){
        this.modalService.destroy();
        this.modalService.isError = false;
        this.modalService.isSuccess = true;
        this.modalService.msg = this.translate.instant('welcome_you_can_place_your_bets');
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>{
          this.modalService.showModal();
        }, 750)
      }else {
        this.msgError = true;
        this.errData.msg = response.msg;
      }
      this.userManagerService.verifyUser = false;
      // this.storageService.save('user', this.userManagerService.userData.session);
      // this.storageService.save('user', this.userData);
      // }
      // this.userManagerService.onLogIn.next(1);
    }).catch(msg => {
      // this.modalService.msg = this.translate.instant(msg);
      this.errData.msg = this.translate.instant(msg)
    }).finally(() => {
      this.userManagerService.isProcessing = false;

    });
  }
  isEmailValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  closeView() {
    this.modalService.destroy();
  }
  validate() {

    const params = this.verificationData;
    params.agent_id = this.user.lg_AGENT_ID;
    this.userManagerService.isProcessing = true;
    this.userManagerService.resetPassword(params).then(data => {
      this.userManagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = data.msg;
      } else {
        // this.modalService.destroy();
        this.modalService.isError = true;
        this.modalService.msg = data.msg;
        // this.modalService.showModal();
      }
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isError = true;
      this.modalService.msg = 'connection_error';
    }).finally(() => {

      clearTimeout(this.timeout);
      this.timeout = setTimeout(()=>{
        this.userManagerService.isProcessing = false;
        this.modalService.showModal();
        }, 750)
    });
  }

  ngOnDestroy():void {
    if(this.timeout) clearTimeout(this.timeout);
  }
}
