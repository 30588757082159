// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const RABBIT_CONFIG = {
  USER: "root",
  PASSWD: "admin",
  ACCUSER: "root",
  ACCPASSWD: "admin",
  EXCHANGE: "lonanionline",
  QUEUE: "flexbet.agent",
};

export const USER_RABBIT_CONFIG = {
  USER: "pmuweb_ws",
  PASSWD: "baY2CiG39SxR8flO",
  EXCHANGE: "betradarfeedsuof",
  QUEUE: "flexbet.agent.",
};

export const AuthParams = {
  RequestHmacKey: "sportcash",
  username: "sportcash",
  secret: "sportcash",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
