import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SkeletonServiceService } from '../../skeleton-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {

  constructor(private router: Router, public uiService: SkeletonServiceService) { }
  menus: Array<any> = [];
  socialMediaLinks={
    facebook:'',
    twitter:'',
    instagram:''
  }
  partners = [
    {src : undefined, width: undefined, href: undefined },
  ]
  ngAfterViewInit() {
    switch(this.uiService.theme.name){
      case 'lonaci': case'flexbet':
        this.socialMediaLinks.facebook= ' ';
        this.socialMediaLinks.twitter = ' ';
        this.socialMediaLinks.instagram = ' ';
        this.partners = [
          {src : 'assets/images/parentalResp_fr.png', width: 300, href: undefined },
          {src : 'assets/images/DiZaPay.png', width: 70, href: undefined },
          {src : 'assets/images/ORANGE.jpg', width: 75, href: undefined },
          {src : 'assets/images/MOOV.png', width: 80, href: undefined },
          {src : 'assets/images/mobile-money.jpg', width: 95, href: undefined },
          {src : 'assets/images/VISAUBA.png', width: 80, href: undefined }
        ],
        this.uiService.menus = [
          { name: 'about', id: '1', link: ''},
          { name: 'notice', id: '2', link: ''},
        // { name: 'limits', id: '4', link: '' },
        { name: 'play_responsibly', id: '5', link: '' },
        //  { name: 'contact', id: '6', link: '' }

      ]
        break;
      case 'pmug':
        this.socialMediaLinks.facebook= '';
        this.socialMediaLinks.twitter = '';
        this.socialMediaLinks.instagram = '';
        this.partners = [
          {src : 'assets/images/parentalResp_fr.png', width: 300, href: undefined },
        ];
        this.uiService.menus = [
          { name: 'notice', id: '2', link: '' },
          { name: 'faq', id: '3', link: '' },
          { name: 'limits', id: '4', link: '' },
          { name: 'play_responsibly', id: '5', link: '' },
          { name: 'contact', id: '6', link: '' } ,
          { name: 'depot-retrait', id: '7', link: '' },
          { name: 'bonus', id: '8', link: '' }
        ]
        break;
      case 'tunisia':
        this.socialMediaLinks.facebook= '';
        this.socialMediaLinks.twitter = '';
        this.socialMediaLinks.instagram = '';
        this.partners = [
          {src : 'assets/images/parentalResp_fr.png', width: 300, href: undefined },
          // {src : 'assets/images/LONAGUI-Vouchers.png', width: 80, href: undefined },
        ];
        this.uiService.menus = [
          { name: 'notice', id: '2', link: '' },
          { name: 'faq', id: '3', link: '' },
          { name: 'limits', id: '4', link: '' },
          { name: 'play_responsibly', id: '5', link: '' },
          { name: 'contact', id: '6', link: '' }
        ]
        break;
      case 'lonagui':
        this.partners = [
          {src : 'assets/images/parentalResp_fr.png', width: 300, href: undefined },
          {src : 'assets/images/LONAGUI-Vouchers.png', width: 100, href: undefined },
          {src : 'assets/images/ORANGE.jpg', width: 75, href: undefined },
          {src : 'assets/images/mobile-money.jpg', width: 100, href: undefined },
        ]
        this.socialMediaLinks.facebook= '';
        this.socialMediaLinks.twitter = ' ';
        this.socialMediaLinks.instagram = ' ';
        this.uiService.menus = [
          { name: 'about', id: '1', link: '' },
          { name: 'notice', id: '2', link: '' },
          { name: 'limits', id: '4', link: '' },
          { name: 'play_responsibly', id: '5', link: '' },
          { name: 'contact', id: '6', link: '' }
        ]
        break;
      case 'pmuc':
        this.partners = [
          {src : 'assets/images/pmuc_parentalResp_fr.png', width: 300, href: undefined },
          // {src : 'assets/images/LONAGUI-Vouchers.png', width: 100, href: undefined },
          // {src : 'assets/images/ORANGE.jpg', width: 75, href: undefined },
          // {src : 'assets/images/mobile-money.jpg', width: 100, href: undefined },
        ]
        this.socialMediaLinks.facebook= '';
        this.socialMediaLinks.twitter = ' ';
        this.socialMediaLinks.instagram = ' ';
        this.uiService.menus = [
          { name: 'about', id: '1', link: '' },
          { name: 'notice', id: '2', link: '' },
          { name: 'limits', id: '4', link: '' },
          { name: 'play_responsibly', id: '5', link: '' },
          { name: 'contact', id: '6', link: '' }
        ]
        break;
      case 'lonani':
        this.partners = []
        this.socialMediaLinks.facebook= '';
        this.socialMediaLinks.twitter = ' ';
        this.socialMediaLinks.instagram = ' ';
        this.uiService.menus = [
          { name: 'about', id: '1', link: '' },
          { name: 'notice', id: '2', link: '' },
          { name: 'limits', id: '4', link: '' },
          { name: 'play_responsibly', id: '5', link: '' },
          { name: 'contact', id: '6', link: '' }
        ]
      }
  }
  openMiscInfo(activeTab) {
    this.router.navigate(['/rules-page'], { queryParams: { tab: activeTab } })

  }
}
