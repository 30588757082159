import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/providers/storage.service';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { SkeletonServiceService } from 'src/app/components/skeleton-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { phoneNumberValidator } from '../../../validators/phone-number.validator';
declare var $: any;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  msg: any;
  accounts = new Array(0)
  personalDetails: any = {};
  procesingOTP = false;
  type: any;
  mode: any;
  otp: any;
  currentVerificationID;
  otpRequired = false;
  shownMsg: boolean;
  password: any = {};
  providerControl: FormControl;
  mobileProviders: any[] = [];
  phoneNumberControl: FormControl;
  showPassword = false;
  showPassword2 = false;
  showPassword3 = false;
  headers: Array<any> = [
    { name: 'personal_data', id: 1 },
    { name: 'change_password', id: 2 },
  ];
  activeHeader: any;
  pins: any = {};
  showAddMethodForm = false;
  userProfile: FormGroup;
  disableEmail: boolean;
  disableCNI: boolean;
  formSubmitted: boolean;
  invalid: boolean;
  codeErr: string;
  timeout: any;
  maxDate: Date;
  errData: any = {};
  constructor(public usermanagerService: UserManagerService,
    private storageService: StorageService,
    public providerService: MyAccountService,
    public configService: ConfigService,
    private skeleton: SkeletonServiceService,
    private modalService: ModalService,
    private translate: TranslateService) {
      if(this.skeleton.theme.name != "lonaci"){
        this.headers.push(    {
          name: 'my_accounts',
          id: 3
        })
      }
    this.activeHeader = this.usermanagerService.showChangePasswordAlert || this.usermanagerService.userData.isExpired ? this.headers[1] : this.headers[0];
    Object.keys(this.usermanagerService.userData).forEach(element => {
      this.personalDetails[element] = this.usermanagerService.userData[element];
      this.enabledFields(this.personalDetails);
    });

    const currentYear = new Date().getFullYear();
    this.maxDate= new Date(currentYear - this.configService.min_age, 0, 1);
  }

  togglePasswordType(val){
    switch (val) {
      case 1:
        this.showPassword = !this.showPassword;
        break;
      case 2:
        this.showPassword2 = !this.showPassword2;
        break;
      case 3:
        this.showPassword3 = !this.showPassword3;
        break;

      default:
        this.showPassword = !this.showPassword;
        break;
    }

  }
  enabledFields(personalDetails) {
    // this.disablePhone = personalDetails.phone_number && this.personalDetails.phone_number.trim() != "" ? true : false;
    this.disableEmail = personalDetails.email && this.personalDetails.email.trim() != '' ? true : false;
    this.disableCNI = personalDetails.cni && this.personalDetails.cni.trim() != '' ? true : false
  }
  addPaymentAccnt() {
    this.showAddMethodForm = true;
  }

  switcHeader(header) {
    let context = this;
    this.msg = undefined;
    this.activeHeader = header;
    if (this.activeHeader.id == 3) {
      this.providerService.getProviders(0).then(data => {
        this.providerControl = new FormControl("", [Validators.required]);
        this.phoneNumberControl = new FormControl("", [Validators.required, phoneNumberValidator(0, [])]);
        this.mobileProviders = this.providerService.paymentProviders[0].filter(provider => provider.is_mobile);
        this.usermanagerService.userData.providers.forEach((userProvider) => {
          let mobileProvider = this.mobileProviders.find(provider => provider.code == userProvider.pvd);
          if(mobileProvider){
            userProvider.name = mobileProvider.name;
          }
          
        });
      })
    }
  }
  ngOnInit() {
    if (!this.usermanagerService.countries.length) {
      this.usermanagerService.getCountryList().then(resp => {
        // console.log(resp)
        this.usermanagerService.countries.forEach(country => {
          this.usermanagerService.dialingCodes.push(country.dialling_code);
        });
        this.usermanagerService.dialingCodes.sort();
      });
    }
    this.usermanagerService.userData.providers
    this.phoneNumberControl = new FormControl('', [Validators.required, Validators.minLength(this.configService.phoneNumberLength), Validators.maxLength(this.configService.phoneNumberLength), Validators.pattern(this.usermanagerService.validationPatterns['phone'])])
    this.initializeForm();
  }

  initializeForm() {
    // console.log("personalDetails", this.personalDetails)
    this.userProfile = new FormGroup({
      first_name: new FormControl(this.personalDetails.first_name, [Validators.minLength(2), Validators.required]),
      last_name: new FormControl(this.personalDetails.last_name, [Validators.minLength(2), Validators.required]),
      email: new FormControl({ value: this.personalDetails.email, disabled: this.disableEmailField() ? true : false }, [Validators.minLength(4),
      Validators.pattern(this.usermanagerService.validationPatterns['email']), Validators.required]),
      cni: new FormControl({ value: this.personalDetails.cni, disabled: this.personalDetails.isQuickSignup && this.disableCNI },
        [Validators.minLength(8), this.configService.useGateway ? Validators.required : Validators.nullValidator, Validators.pattern(this.usermanagerService.validationPatterns['cni'])]),
      // nationality: new FormControl(this.personalDetails.country, [Validators.minLength(2)]),
      // birthday: new FormControl(this.userBirthday, [Validators.required]),
      birthday: new FormControl( formatDate(new Date(this.personalDetails.birthday), "yyyy-MM-dd", "en"), [Validators.required]),
      sex: new FormControl(this.personalDetails.sex, Validators.required),
      // lang: new FormControl(this.personalDetails.lang, Validators.required),
      nationality: new FormControl(this.personalDetails.nationality || this.configService.country),
      city: new FormControl(this.personalDetails.city),
      notif_Channels: new FormControl(this.personalDetails.notif_Channels),
      address: new FormControl(this.personalDetails.address),
      // countrycode: new FormControl(this.config.countryCode, [Validators.minLength(2), Validators.required]),
      phone_number: new FormControl({ value: this.personalDetails.phone_number, disabled: true }),
      account_code: new FormControl({ value: this.personalDetails.account_code, disabled: true }),
      login: new FormControl(this.personalDetails.login),
      town: new FormControl(this.personalDetails.town),
      sid: new FormControl(this.personalDetails.session)
    });
  }
  initMsgs() {
    this.msg = undefined;
  }
  ngAfterViewInit() { }

  disableEmailField(){
    return !this.personalDetails.isQuickSignup || (this.personalDetails.isQuickSignup && this.disableEmail)
  }

  onDateChange(): void {
    if(new Date(this.userProfile.value.birthday) > this.maxDate) {
     this.userProfile.controls.birthday.setErrors({incorrect: true})
    }
  }

  validateOTP() {
    this.procesingOTP = true;
    const requestData = {
      sid: this.usermanagerService.userData.session,
      otp: this.otp,
      id: this.providerControl.value,
      pvd: this.providerControl.value,
    };
    this.usermanagerService.confirmpaymentAccount(requestData).then(response => {
      if(response.hasOwnProperty('erc'))
      switch (response.erc) {
        case "1": case 1:
          this.msg = "successful_otp"
          this.otpRequired = false
          this.usermanagerService.userData.providers = response.data
          this.showAddMethodForm = false;
          break;
        case "0": case 0:
          this.codeErr = response.msg;
        default:
          break;
      }

    }).finally(() => {
      this.procesingOTP = false
    })
  }
  changeNotifPref(pref) {
    if (!this.usermanagerService.userData.notif_Channels)
      this.usermanagerService.userData.notif_Channels = []
    if (this.usermanagerService.userData.notif_Channels && this.usermanagerService.userData.notif_Channels.indexOf(pref) >= 0) {
      this.usermanagerService.userData.notif_Channels.splice(this.usermanagerService.userData.notif_Channels.indexOf(pref), 1)
    } else {
      this.usermanagerService.userData.notif_Channels.push(pref)
    }
  }

  verifyPassword() {
    var pattern = /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/
    // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/

    var checkval = pattern.test(this.password.str_Password);
    // console.log(checkval)
    if (!checkval) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }
  updateAccnt() {
    this.formSubmitted = true;
    this.userProfile.get('account_code').enable();
    this.userProfile.controls.phone_number.status == "DISABLED" ? this.userProfile.get("phone_number").enable() : '';
    this.userProfile.controls.email.status == "DISABLED" ? this.userProfile.get('email').enable() : '';
    this.userProfile.controls.cni.status == "DISABLED" ? this.userProfile.get('cni').enable() : '';
    this.onDateChange();

    if (this.userProfile.invalid) {
      // console.log(this.userProfile.controls);
      this.userProfile.get('phone_number').disable()
        this.userProfile.get('account_code').disable()
        !this.disableEmail && this.personalDetails.isQuickSignup ? "": this.userProfile.get('email').disable()
        !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
      return;
    }
    const requiredFields = ['phone_number', 'cni', 'email'];
    if (this.userProfile.invalid) {
      this.userProfile.get('phone').disable();
      !this.disableEmail && this.personalDetails.isQuickSignup ? "" : this.userProfile.get('email').disable()
      !this.disableCNI && this.personalDetails.isQuickSignup ? "": this.userProfile.get('cni').disable();

      requiredFields.forEach((input: string) => {

        if (this.userProfile.controls[input].invalid || (this.userProfile.get(input).status == 'DISABLED' && !this.userProfile.get(input).value)) {
          this.errData[input] = this.translate.instant("field_required", {field: input});
          this.userProfile.get(input).enable();
        } else {
          this.errData[input] = null
        }
      });
      return;
    }

    this.usermanagerService.isProcessing = true;
    this.usermanagerService.updateUser(this.userProfile.value).then(data => {
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        
        // this.usermanagerService.userData = this.personalDetails;
        this.updateData(this.userProfile.value);
        this.usermanagerService.userData.profile_completed = data.data ? data.data.b_Profile_Completed : '';
        this.userProfile.get("phone_number").disable()
        this.userProfile.get('email').disable()
        this.userProfile.get('cni').disable()
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>{
          this.modalService.msg = 'Succesfully_updated_profile';
          this.modalService.showModal();
          this.storageService.save('user',this.usermanagerService.userData.session);
        }, 750)
      } else {
        // this.msg = data.msg;
        // if(data.erc == '0'){
        //   this.msg = data.msg
        // }else{
        //   this.msg = 'connection_error'
        // }
        this.msg = data.erc == 0 && data.msg ? data.msg : 'connection_error';
        this.userProfile.get('phone_number').disable()
        this.userProfile.get('account_code').disable()
        !this.disableEmail && this.personalDetails.isQuickSignup ? "": this.userProfile.get('email').disable()
        !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
      }

      this.usermanagerService.isProcessing = false;
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isSuccess = false;
      this.modalService.isError = true;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();
    }).finally(() => {
      this.usermanagerService.isProcessing = false;
    });
  }
  updateData(data){
    Object.keys(data).forEach(element => {
       this.usermanagerService.userData[element] = data[element];
      // if (this.personalDetails.birthday) {
      //   this.personalDetails.birthday = this.helper.initDate(this.personalDetails.birthday);
      // }
    });
  }
  changePasswords() {
    if (this.password.str_Password_old == this.password.str_Password) {
      this.msg = "old_pass_equal_new_passs";
      return;
    }
    if (this.password.str_Password == this.usermanagerService.userData.login) {
      this.msg = "username_can_not_be_same_as_password";
      return;
    }
    if (this.password.str_Password != this.password.str_Password2) {
      this.msg = "password_confirmation_incorrect";
      return;
    }
    this.password.str_Login = this.usermanagerService.userData.login;
    this.usermanagerService.isProcessing = true;
    this.usermanagerService.changePassword(this.password).then(data => {
      this.usermanagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.usermanagerService.showChangePasswordAlert = false;
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.msg = "password_update_success";
        this.modalService.showModal();
        this.usermanagerService.userData.isExpired = false;
      } else {
        this.msg = data.msg;
      }


    }).catch(data => {
      // this.modalService.destroy();
      // this.modalService.isSuccess = false;
      // this.modalService.isError = false;
      this.msg  = 'connection_error';
      // this.msg  = 'Oops Connection Error';
      // this.modalService.showModal();
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      });
  }
  sendNumberOTP() {
    this.usermanagerService.isProcessing = true;
    const request = {
      sid: this.usermanagerService.userData.session,
      agtId: this.usermanagerService.userData.lg_AGENT_ID,
      pvd: this.providerControl.value,
      accnt: this.phoneNumberControl.value
    }
    // debugger
    this.usermanagerService.saveAccount(request).then(data => {
      // var temp = data
      if(data.erc && data.erc == 1){
        this.otpRequired = true;
      }else if(data.erc) {
        this.msg = data.msg;
      }

      console.log(data);

    }).catch(err => {
      if (err.erc)
        this.msg = err.msg
      else
        this.msg = 'connection_error'
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      })
    // console.log("adding new number")
  }
  changePin() {
    this.usermanagerService.isProcessing = true;
    if (this.pins.str_Pin == this.pins.str_Pin2) {
      this.pins.str_Login = this.usermanagerService.userData.str_Login;
      this.usermanagerService.changePin(this.pins).then(data => {
        this.usermanagerService.isProcessing = false;
        if (data.hasOwnProperty('erc') && data.erc == '1') {
          this.modalService.destroy();
          this.modalService.isSuccess = true;
          this.modalService.msg = data.msg;
          this.modalService.showModal();
        }
        else {
          this.msg = data.msg;
        }
      }).catch(data => {
        this.modalService.destroy();
        this.modalService.isSuccess = false;
        this.modalService.isError = false;
        this.modalService.msg = 'connection_error';
        // this.modalService.msg = 'Oops Connection Error';
        this.modalService.showModal();
      })
        .finally(() => {
          this.usermanagerService.isProcessing = false;
        });

    } else {
      this.msg = "err_PINS_mismatch";
    }

  }

  closeOtp(){
    this.otpRequired = false;
  }

  verifyEmail(event: FocusEvent) {
    if (event.target['value']) {
      // const params = { email: '' };
      // params.email = event.target['value'];
      const params = event.target['value'];
      this.usermanagerService.verifyEmail(params).then((response) => {
        if (response.hasOwnProperty('erc') && response.hasOwnProperty('data') && response.erc == '1') {
            this.userProfile.controls['email'].setErrors({
              userExist: response.data,
            });
        }
        else if (response[0]) {
          if (response[0].Reponse == 'valid') {
            this.userProfile.controls['email'].setErrors({
              userExist: true,
            });
            // console.log("passed here 1")
          }
          // else if(response[0].Reponse == 'invalid' ){
          //   this.registrationForm.controls['email'].setErrors({
          //     userExist: false,
          //   });
          //   console.log("passed here")
          // }

        }
        else {
          this.userProfile.controls['email'].setErrors({
            others: true,
          });
        }
      },
      () => {
        this.userProfile.controls['email'].setErrors({
          others: true,
        });
      }
    );
    }
    else {
      this.userProfile.controls['email'].setErrors({
        userExist: false,
      });
    }
  }

  selectProvider(providerCode: string){
    let selectedProvider = this.mobileProviders.find((provider) => provider.code === providerCode);

    if(selectedProvider){
      let mask: string = selectedProvider.mask;
      if(mask && mask.indexOf('#') > -1 ){
          const [startDigits, phoneLength] = mask.split('#');
          this.phoneNumberControl = new FormControl('', [Validators.required, phoneNumberValidator(Number(phoneLength), startDigits.split('-'))]);
      }else {
          this.phoneNumberControl = new FormControl('', [Validators.required, phoneNumberValidator(0, [])]);
      }
    }
  }

  get validAccountPaymentForm() {
    return this.phoneNumberControl.valid && this.providerControl.valid;
  }

}
