import { WebsocketService } from './../../../providers/WebsocketService';
import { ResetPassowrdComponent } from './../../reset-passowrd/reset-passowrd.component';
import { StorageService } from './../../../providers/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { MyAccountComponent } from './../../../pages/my-account/my-account.component';
import { ModalService } from './../../../providers/modal-service/ModalService';
import { SkeletonServiceService } from './../../skeleton-service.service';
import { Component, OnInit } from '@angular/core';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import * as $ from 'jquery';
import { ActivateAccountComponent } from '../../activate-account/activate-account.component';
import { Language } from 'src/app/utilities/utils';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  clock: Date;
  showPassword = false;
  username: string;
  phoneNumber: string;
  password: any;
  languagesOffered: Array<Language> = new Array(0);
  navmenus: Array<any> = [];
  selectedLanguage: Language;
  loginByPsuedo: boolean = true;
  date = moment(new Date()).format('DD-MM-YYYY');
  onhomePage: boolean;
  timeout: any;
  constructor(
    private userManagementService: UserManagerService,
    public skeletonServiceService: SkeletonServiceService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    public userManagerService: UserManagerService,
    public route: Router,
    public configService: ConfigService,
    public storage: StorageService,
    public accountPanelServie: MyAccountService,
    public websocketService: WebsocketService,
    private modalService: ModalService
  ) {
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        if (val.url && val.url.indexOf('turf/home') > -1) {
          this.onhomePage = true;
        } else {
          this.onhomePage = false
        }
        });

    this.navmenus = [

      {
        name: 'sportcash',
        title: 'sportcash',
        newTab: true,
        hidden: true,
        authrequired: false,
        id: '1',
        link: 'http://sportcash.net/',
      },
      {
        name: moment(new Date()).format('DD-MM-YYYY'),
        title: moment(new Date()).format('DD-MM-YYYY'),
        hidden: false,
        authrequired: false,
        icon: 'assets/images/calendar-1.svg',
        id: '1',
        // link: "/live",
      },

      {
        name: 'prematch',
        title: 'prematch',
        hidden: true,
        authrequired: false,
        id: '2',
        link: '/matches',
      },
      {
        name: 'casino',
        title: 'casino',
        hidden: true,
        authrequired: false,
        id: '3',
        link: '/casino',
      },
      {
        name: 'virtual_sport',
        title: 'virtual_sport',
        hidden: true,
        authrequired: false,
        id: '4',
        link: '/virtual',
      },
      {
        name: 'PMU',
        title: 'turf',
        hidden: true,
        authrequired: false,
        id: '17',
        link: '/turf',
      },
      {
        name: 'alr',
        title: 'turf',
        hidden: true,
        authrequired: false,
        id: '17',
        link: '/turf',
      },

      {
        name: 'loto bonheur',
        title: 'lotto',
        newTab: true,
        hidden: true,
        authrequired: false,
        id: '5',
        link: 'https://lotobonheur.nospetitstrucs.com/',
      },
      {
        name: 'grattage',
        title: 'grattage',
        hidden: true,
        authrequired: false,
        id: '15',
        link: '/grattage',
      },
      {
        name: 'results',
        title: '',
        hidden: true,
        authrequired: false,
        id: '10',
        link: '/results',
      },
      {
        name: 'promotions',
        title: 'promotions',
        hidden: true,
        authrequired: false,
        id: '7',
        link: '/promotions',
      },
      {
        name: 'home_page',
        title: 'homePage',
        newTab: true,
        hidden: this.skeletonServiceService.theme.name == "lonaci" ? false : true,
        authrequired: false,
        id: '1',
        link: this.configService.PROXIES.homePage,
      },
      {
        name: 'sportcash',
        title: 'sport_site',
        newTab: true,
        hidden: true,
        authrequired: false,
        id: '1',
        link: this.configService.PROXIES.sport_site,
      },
    ];
    // this.navmenus =[{
    //   name:new Date()
    // }]
    this.languagesOffered = this.userManagementService.defaultLanguages;
  }

  ngOnInit() {
    setInterval(() => {
      this.skeletonServiceService.currentTime = new Date();
    }, 10000);
  }

  getDefaultLang(){
    const lang : any = this.storage.get("pmu-lang", {})
    return lang ? JSON.parse(lang).code : "fr"
  }

  ngAfterViewInit() {
    // console.log("default lang", this.translate.getBrowserLang(), this.userManagementService.userData ? this.userManagementService.userData.lang : "no user lang");

    this.selectedLanguage = this.languagesOffered.filter((lang) => {
      if (this.userManagementService.userData) {
        return lang.code === this.userManagementService.userData.lang;
      } else {
        return lang.code === this.getDefaultLang();
      }
    })[0];

    // this.selectLanguage(this.selectedLanguage.code)
  }
  selectLanguage(lang) {
    this.translate.use(lang.code);
    this.translate.setDefaultLang(lang.code);
    this.selectedLanguage = lang;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(()=>{
      this.storage.save("pmu-lang", this.selectedLanguage)
    }, 750)
  }
  bonusPage() {
    if (this.userManagerService.userData.balances.bon.length > 0) {
      this.route.navigateByUrl("/bonus");
    }
  }
  registeruser() {
    if (this.skeletonServiceService.theme.name == "lonaci") {
      window.location.href = this.configService.PROXIES.homePage + (this.configService.quick_signup_enabled == 1 ? "/fast-signup" : '/registration');
    } else {
      this.route.navigateByUrl('registration')
    }
  }

  openlink(url) {
    url = this.userManagementService && this.userManagementService.userData ? this.configService.PROXIES[url.title] + '?session='
      + this.userManagementService.userData.session : this.configService.PROXIES[url.title];
    window.location.href = url;
  }
  closeModal($event) { }
  initiateLogin() {
    const loginParams: any = {
      username: this.loginByPsuedo ? this.username : (this.configService.country_code.toString().concat(this.phoneNumber)),
      password: this.password
    }
    // this.userManagerService.username = (!this.loginByPsuedo ? this.configService.country_code : "") + this.username;
    // this.userManagerService.password = this.password;
    this.userManagerService
      .login(loginParams, this.loginByPsuedo)
      .then((data) => {
        if (data) {
          this.websocketService.connect_session();
          window.location.href.indexOf('registration') > -1 ? this.route.navigateByUrl('/home') : ""
          if (
            this.userManagerService.showChangePasswordAlert ||
            this.userManagerService.userData.isExpired
          ) {
            this.modalService.init(MyAccountComponent, { activeTab: '1' }, {});
          }
          // this.username = '';
        }
      })
      .catch((err) => {

      })
      .finally(() => {
        this.password = '';
        this.username = '';
        this.phoneNumber = "";
      });
  }
  openAccounts(tabId) {
    this.skeletonServiceService.theme.name == "lonaci" ?
      window.location.href = `${this.configService.PROXIES.homePage}?${this.userManagerService.setLoggedInUserUrlParams()}&page=my-account&redirect=${encodeURIComponent(window.location.href)}` : this.modalService.init(MyAccountComponent, { activeTab: tabId }, {})

    // this.modalService.init(MyAccountComponent, { activeTab: tabId }, {});   http://10.12.1.186:4202/#/

  }
  forgotPassword(){
    this.modalService.destroy();
    this.modalService.init(ResetPassowrdComponent, {}, {});
  }
  gotoUrl(){
    let url = this.configService.EndPoints.userManagementEndpoint +'?' + this.userManagerService.setLoggedInUserUrlParams();
    url = this.configService.decorateUrl(url);
    window.location.href = url;
  }
  disconnectUser() {
    const context = this;
    const param = {
      sessionToken: context.userManagerService.userData.session,
    };
    context.userManagerService
      .logout(param)
      .then((data) => { })
      .finally(() => {
        this.websocketService.disconnect();
        this.userManagerService.cleanLocalSession();
        this.userManagementService.userData = undefined;
      });
  }
  forgotenPassword(tab) {
    if (this.skeletonServiceService.theme.name == 'lonaci') {
      let url = this.configService.PROXIES.homePage
      url = url.includes('?') ? url + '&' : url + '?';
      url = url + 'page=' + tab;

      window.location.href = url
    }
    else if (tab == "forgot-password")
      this.modalService.init(ResetPassowrdComponent, {}, {});
    else {
      this.modalService.init(ActivateAccountComponent, {}, {});
    }
  }
  activateAccount() {
    this.modalService.init(ActivateAccountComponent, {}, {});
  }
  showTransactions() {
    this.accountPanelServie.activeAccountstab = 3;
    this.modalService.init(MyAccountComponent, { activeTab: 3 }, {});
  }
  setdate() {
    $('#collapse2').addClass('collapsed');
    $('#collapseExample2').removeClass('show');

    $('#collapseExample').attr('aria-expanded', 'true');
    $('#collapseExample').addClass('collapse');
    $('#collapseExample').addClass('show');
    $('#collapse1').removeClass('collapsed');
  }

  toggleSigninMode(){
    this.loginByPsuedo = !this.loginByPsuedo;
    this.userManagementService.error_msg = null;
  }

  togglePasswordType(){
    this.showPassword = !this.showPassword
  }
}
