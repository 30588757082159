import { Injectable } from '@angular/core';
import { th } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
   setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

   getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

   checkCookie(cname) {
    var user = this.getCookie(cname);
    if (user != "") {
      return true
    } else {
     return false
    }
  }
  public setCookieObject(name, value, exdays?) {
    // var d = new Date();
    exdays = exdays ? exdays : 60 * 24
    // d.setTime(d.getTime() + (exdays * 60 * 1000));
    // var expires = "expires="+d.toUTCString();
    // var cookie = [name, '=', JSON.stringify(value), + ";" + expires + ";path=/", + '; domain=.', window.location.host.toString(), '; path=/;'].join('');
    // document.cookie = cookie;
    this.setCookie(name, JSON.stringify(value), exdays)
  }
  public  getCookieObject(name, value) {
    var result:any = this.getCookie(name);
   if(result){
    result = result.toString().replace(/\\n/g, "\\n")
               .replace(/\\'/g, "\\'")
               .replace(/\\"/g, '\\"')
               .replace(/\\&/g, "\\&")
               .replace(/\\r/g, "\\r")
               .replace(/\\t/g, "\\t")
               .replace(/\\b/g, "\\b")
               .replace(/\\f/g, "\\f");
// remove non-printable and other non-valid JSON chars
   result = result.replace(/[\u0000-\u0019]+/g,"");
   }
  //  console.log('response data?', result)
    result && (result = JSON.parse(result));
    return result ? result : value;
   }
   public delete_cookie(name) {
    //  let x = window.location.host.toString();
    document.cookie = [name, '=; expires=expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;', ].join('');
  }
  public save(key, value) {
    localStorage.setItem(key, typeof value == "string" ? value : JSON.stringify(value));
  }
  public get(key, value?) {
    return localStorage.getItem(key);
  }
  public getInt(key, dvalue): number {
    return Number(localStorage.getItem(key));
  }
  public getString(key, value): string {
    return localStorage.getItem(key);
  }
  public getObject(key, value): any {
    try {
      if (localStorage.getItem(key) != null) {
        return JSON.parse(localStorage.getItem(key));
      } else { return value; }
    } catch (err) {
      return value;
    }
  }
  public deleteItem(key) {
    return localStorage.removeItem(key);
  }
}
