import { ConfigService } from './providers/config/config.service';
import { HttpService } from './wrappers/Http';
import { StorageService } from './providers/storage.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { Component, OnInit } from '@angular/core';
import { ModalService } from './providers/modal-service/ModalService';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from './providers/WebsocketService';
import { SkeletonServiceService } from './components/skeleton-service.service';
import { BetslipServiceService } from './providers/services/betslip/betslip-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TurfServiceService } from './providers/services/turf-service/turf-service.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResetPassowrdComponent } from './components/reset-passowrd/reset-passowrd.component';

declare var $: any;
let context;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'PMU';
  idleInterval: NodeJS.Timer;
  userIdleTime: any;
  // router: any;
  session = undefined;
  id = undefined;
  url = window.location.href;
  s1: any = '';
  subscription: Subscription = new Subscription();
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  title = this.getSiteTitle();
  hideHeader: boolean;
  page: any;
  showConfirmationLoader: boolean;
  params: {};
  accountConfirmationObj = { status: '', msg: '', title: '' };
  constructor(
    private userManagementService: UserManagerService,
    public modalService: ModalService,
    private socketHandler: WebsocketService,
    private storage: StorageService,
    private configsService: ConfigService,
    public uiService: SkeletonServiceService,
    private turfService: TurfServiceService,
    private requestHandler: HttpService,
    private betService: BetslipServiceService,
    private metaTagService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private translate: TranslateService) {
    // this.configsService.loadConfigs();
    this.initializeTheme();
    context = this;
    this.idleLogout();
    this.loadBet()
    this.titleService.setTitle(this.title)
    this.storeUserData();


  }
  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        if (val.url && val.url.indexOf("age-verification") > -1) {
          this.hideHeader = true;
        } else {
          this.hideHeader = false;
        }
      });

      this.userManagementService.resetPass.subscribe((res)=>{
        if(res === 1){
          this.forgotPassword();
            return;
        }
      })
  }
  idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
    function yourFunction() {
      // console.log("Executing")
    }

    function resetTimer() {
      context.userManagementService.lastActiveTime = new Date();
      clearTimeout(t);
      t = setTimeout(yourFunction, 10000);  // time is in milliseconds
    }
  }

  fetchSlides(){
    this.userManagementService.loadSlides({ channel_id: 'PMU', status: true }).then(res => {
      if(res.hasOwnProperty('slides'))
        return this.userManagementService.sliderItems = res.slides;
      if(res.hasOwnProperty('data'))
        return this.userManagementService.sliderItems = res.data;

    })
  }

  getDefaultLang(){
    const lang : any = this.storage.get("pmu-lang", {})
    return lang ? JSON.parse(lang).code : "fr"
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.loadConf();
    this.configsService.lang = this.userManagementService.userData && this.userManagementService.userData.lang ? this.userManagementService.userData.lang : this.getDefaultLang();
    this.translate.use(this.configsService.lang);
      this.fetchSlides()
    if (this.configsService.useGateway) {
      if (this.url.split('?').length > 1) {
        if (this.url.includes('?')) {
          var dataContext = this.url.split('?')[1];
          this.params = {};
          dataContext =
            dataContext && dataContext.includes('#/&')
              ? dataContext.split('#/&')[1]
              : dataContext;
          if (dataContext) {
            const dataArray = dataContext.split('&');

            dataArray.forEach((data) => {
              let keyValue = data.split('=');
              this.params[keyValue[0]] = keyValue[1];
            });

            this.page = this.params['page'];


            if (this.params['ValidateCode']) {
              this.page = 'confirmation';
            }
            this.openPage();
          }
          this.id = { session: this.params['session'] };
          // this.id = {session: this.id};
          // window.location.href = this.url.split('session')[0];
          this.router.navigate([], {
            queryParams: {
              session: null,
            },
            queryParamsHandling: 'merge'
          });
        } else {
          this.id = undefined;
        }
        // this.id = this.url.includes('session')? this.url.split('=')[1] : undefined;
        // this.id = {session: this.id};

        // console.log(this.id);
      }

      if (this.storage.checkCookie('session')) {
        this.session = this.storage.getCookie('session');
        // console.log(this.session)
      }
      if (this.session || this.id) {
        // if (!this.configsService.USER_AUTHORISATION.access_token) {
        //   this.configsService.USER_AUTHORISATION = this.storage.getObject('auth', {})
        // }
        this.userManagementService.authSession(this.id ? this.id : { session: this.session }).then(res => {
          if (this.configsService.useGateway) {
            this.socketHandler.connect_session();
          }
          window.location.href.indexOf('registration') > -1 ? this.router.navigateByUrl('/home') : ""
        });

        return;
      }

    } else {

      // if (this.storage.checkCookie('userdata')) {
      let userData = this.storage.getObject('user', {});
      if (userData.hasOwnProperty("account_code")) {
        this.userManagementService.userData = userData;
        this.userManagementService.onLogIn.next(1);
      }
      // }

    }


  }
  loadConf() {
    try{
      this.socketHandler.connectRaces();
    } catch(err){
      // console.log(err)
    }
    this.requestHandler.get_request(this.configsService.PROXIES.sportcash , this.configsService.EndPoints.systemConfigs, {}).then(response => {
      if (response.hasOwnProperty('erc') && response.erc == "1") {
        try {
          this.configsService.ClientSystemConfigs.forEach(item => {
            var configVal: any;
            switch (item.name) {
              case "quick_reg_input_fields":
                configVal = this.getValue_new(item.key, response.data, item.default);
                this.configsService[item.name] = this.uiService.stringToArray(configVal);
                break;
              case "nationale_types_offered":
                 configVal = this.getValue_new(item.key, response.data, item.default);
                //  console.log(item.name, configVal);

                this.configsService[item.name] = this.uiService.stringToArray(configVal);
                break;
              default:
                this.configsService[item.name] = this.getValue_new(item.key, response.data, item.default);
                break;
              }
          });
        } catch (error) {
          throw ("error processing configs" + error)
        } finally {
          // this.configsService.country_code = "+227"
        }
      }

      if ((this.configsService.age_verification_required == 1) && !this.storage.get('isUserAgeVerified')) {
        this.router.navigateByUrl('age-verification');
      }
    });
  }

  openPage() {
    switch (this.page) {
      // case 'my-account':
      //   this.modalService.init(MyAccountComponent, { activeTab: 2 }, {});
      //   break;
      // case 'forgot-password':
      //   this.modalService.init(ResetPassowrdComponent, {}, {});
      //   break;
      // case 'account-activation':
      //   this.modalService.init(ActivateAccountComponent, {}, {});
      //   break;
      case 'register':
        let url = this.configsService.quick_signup_enabled == 1 ? 'fast-signup': 'registration';
        this.router.navigate([url]);
        break;
      case 'confirmation':
        $('#confirmationModal').modal('show');
        this.showConfirmationLoader = true;
        let requestParams = this.params['ValidateCode'];
        const request = requestParams.split('@');
        const param: any = {
          activationCode: request[1],
          time: request[2],
          agentId: request[0],
          lang: this.configsService.lang
        };
        // param.activationCode = request[1];
        // param.time = request[2];
        // param.agentId = request[0];
        // param['lang'] = navigator.language.split('-')[0];
        this.userManagementService.confirmAccount(param).then((res) => {
          this.accountConfirmationObj.msg = res.erc == 1 ? 'welcome_you_can_place_your_bets' : res.msg;
          this.accountConfirmationObj.status = res.erc == 1 ? 'success' : 'error';
          this.accountConfirmationObj.title = res.erc == 1 ? 'ml_account_activated' : "err_account_activation"
          if (this.session) {
            this.router.navigate([], {
              queryParams: {
                session: null,
              },
              queryParamsHandling: 'merge',
            });
          }
        }).catch(err => {
          this.accountConfirmationObj.msg = 'connection_error'

        })
          .finally(() => {
            this.showConfirmationLoader = false;
          });
        break;
      default: break;
    }
  }
  forgotPassword(){
    this.userManagementService.passModal = true;
    this.modalService.destroy();
    this.modalService.init(ResetPassowrdComponent, {}, {});
  }
  closeModal(event?) {
    $("#confirmationModal").modal('hide')
  }

  getValue_new(key: string, arr: Array<any>, def:any): any {
    if (!arr.length) return def;
    let returnVal: any = arr.find((config) => config.key === key);
    if(!returnVal) return def;
    switch (typeof def) {
      case 'string':
      case 'object':
        return returnVal['value'];
      case 'number':
        return parseInt(returnVal['value']);
      case 'boolean':
        return JSON.parse(returnVal['value']);
      default:
        return def;
    }
  }
  getValue(configs: Array<any>, arg1: string): any {
    let valueToReturn;
    configs.forEach(config => {
      if (config.key === arg1) {
        valueToReturn = config.value;
      }
    });
    return valueToReturn;
  }
  removeModal() {
    if (this.userManagementService.showChangePasswordAlert) {
      return;
    }
    this.modalService.destroy();
  }
  initializeTheme() {
    for (const property of Object.keys(this.uiService.theme.properties)) {
      document.documentElement.style.setProperty(property, this.uiService.theme.properties[property]);
    }
    this.configsService.useGateway = this.uiService.theme.useGateway;
    this.configsService.whiteIconTheme = this.uiService.theme.whiteIcon;
    this.configsService.country = this.uiService.theme.country;
  }

  getSiteTitle() {
    let title = ''
    switch (this.uiService.theme.name) {
      case 'lonaci':
        title = 'Paris en ligne | LONACI';
        this.favIcon.href = 'assets/icons/favicon.png';
        this.s1 = 'https://embed.tawk.to/5e90e2fb35bcbb0c9aafd865/default';
        this.userManagementService.siteTitle = "LONACI";
        break;

      case 'lonagui':
        title = 'Paris Hippique en ligne | LONAGUI';
        this.favIcon.href = 'assets/icons/lonaguifavicon.png';
        this.s1 = 'https://embed.tawk.to/5e90e2fb35bcbb0c9aafd865/default';
        this.userManagementService.siteTitle = "LONAGUI";
        break;

      case 'lonani':
        title = 'Paris en ligne | LONANI';
        this.favIcon.href = 'assets/icons/lonani/favicon.ico';
        this.s1 = 'https://embed.tawk.to/5e90e2fb35bcbb0c9aafd865/default';
        this.userManagementService.siteTitle = "LONANI";
        break;

      case 'pmug':
        title = 'Paris en ligne | PMUG';
        this.favIcon.href = 'assets/icons/pmugfavicon.png';
        this.s1 = '';
        this.userManagementService.siteTitle = "PMUG";
        break;

      case 'pmuc':
        title = 'Paris Hippique en ligne | PMUC';
        this.favIcon.href = 'assets/icons/pmucfavicon.ico';
        this.s1 = '';
        this.userManagementService.siteTitle = "PMUC";
        break;

      default:
        title = `Paris en ligne | ${this.uiService.theme.name.toString().toUpperCase()}`;
        this.favIcon.href = 'assets/icons/favicon.png';
        this.s1 = 'https://embed.tawk.to/5e90e2fb35bcbb0c9aafd865/default';
        this.userManagementService.siteTitle = this.uiService.theme.name.toString().toUpperCase();
        break;
    }
    return title;
  }

  loadBet() {
    this.turfService.betObject = this.storage.getCookieObject("betObject", {});
    this.turfService.betsArray = this.storage.getCookieObject("betsArray", []);
    this.turfService.activeBets = this.storage.getCookieObject("activeBets", []);
   
    // this.turfService.activeBets = this.turfService.betsArray;
    // if (this.turfService.betObject && this.turfService.betObject.hasOwnProperty('game')) {
    //   this.turfService.betsArray.push(this.turfService.betObject)
    //   this.turfService.activeBets.push(this.turfService.betObject);
    // }
    // else{
    //   this.turfService.betObject = {};
    // }
    this.turfService.activeRaceInSelectedMeeting = this.storage.getCookieObject("activeRace", {});
    this.turfService.activeGameObject = this.storage.getCookieObject("activeGameObject", {});
    this.LoadSelections()
    this.turfService.spotIcon = this.turfService.activeGameObject.spot && this.turfService.activeGameObject.spot.includes('|SI, CE');
    this.turfService.spotGametype = this.storage.getCookie("spotGametype") == 'true';
    this.turfService.calculateNewAmount(this.turfService.betObject)

  }
  LoadSelections() {
    let selections = this.storage.getCookie("selectedHorses") && this.storage.getCookie("selectedHorses").split('|') ? this.storage.getCookie("selectedHorses").split('|') : [];
    selections.forEach(item => {
      this.turfService.selectedHorses.add(item)
    })
  }
  storeUserData() {
    if (this.configsService.useGateway)
      return;
    this.subscription = this.userManagementService.onLogIn.subscribe(res => {
      if (res == 1) {
        this.storage.save("user", this.userManagementService.userData)
      }
    })
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



}
