import { InitialsPipe } from './initials.pipe';
import { NgModule } from '@angular/core';
import { DatePipe } from './date.pipe';
import { SortByPipe } from './sort-by.pipe';
import { SafeHtmlPipePipe } from './safe-html-pipe.pipe';
import { TurfDatePipe } from './turf-date/turf-date.pipe';
import { ImageLoaderPipe } from './image-loader.pipe';
@NgModule({
  declarations: [InitialsPipe, DatePipe, SortByPipe, SafeHtmlPipePipe,TurfDatePipe, ImageLoaderPipe],
  exports: [InitialsPipe, SortByPipe,SafeHtmlPipePipe,TurfDatePipe, ImageLoaderPipe],
})
export class PipesModule { }
