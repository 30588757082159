import { BetslipServiceService } from './providers/services/betslip/betslip-service.service';
import { ModalService, DialogOverviewExampleDialog } from './providers/modal-service/ModalService';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SportifWebRoutingModule } from './app-routing.module';
import { SkeletonServiceService } from './components/skeleton-service.service';
import { DomService } from './providers/modal-service/DOMService';
import { UserManagerService } from './providers/services/userManager/user-manager.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpService } from './wrappers/Http';
import { SportServiceService } from './providers/services/sport-service/sport-service.service';
import { TimeHandlerService } from './providers/time-handler.service';
import { SkeletonComponentsModule } from './components/skeleton-components/skeleton-components.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageService } from './providers/storage.service';
import { WebsocketService } from './providers/WebsocketService';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ConfigService } from './providers/config/config.service';
import {MatDialogModule} from '@angular/material/dialog';
import { ComponentsModule } from './components/components.module';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function configFactory(config: ConfigService) {
  return  () => config.loadConfigs();
}

@NgModule({
  declarations: [
    AppComponent,
    DialogOverviewExampleDialog
  ],
  entryComponents:[DialogOverviewExampleDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SkeletonComponentsModule,
    HttpClientModule,
    MatDialogModule,
    ComponentsModule,
    SportifWebRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('src/ngsw-config.json', { enabled: environment.production }),
  ],
  providers: [StorageService, TranslateService, SkeletonServiceService,
    ModalService, UserManagerService, DomService, TimeHandlerService, HttpService,
    BetslipServiceService, SportServiceService, WebsocketService,
    ConfigService, {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
