import { TurfServiceService } from 'src/app/providers/services/turf-service/turf-service.service';
import { UserManagerService } from './services/userManager/user-manager.service';
import { BetslipServiceService } from './services/betslip/betslip-service.service';
import { TimeHandlerService } from './time-handler.service';
import { Injectable, ɵConsole } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from "sockjs-client"
import { SportServiceService } from './services/sport-service/sport-service.service';
import * as _ from 'lodash';
import { SkeletonServiceService } from '../components/skeleton-service.service';
import { ConfigService } from './config/config.service';
import { ModalService } from './modal-service/ModalService';
import { Subject } from 'rxjs';
@Injectable()
export class WebsocketService {

  stompClient: any;
  stompclient: any;
  cagnoteGames: any;
  getCagnote: Subject<[]> = new Subject();
  message: any;
  partants: any;
  checkIdletime = false;
  constructor(

    private betService: BetslipServiceService,
    private configs: ConfigService,
    private userServices: UserManagerService,
    private sportService: SportServiceService,
    private turfService: TurfServiceService) {
    this.userServices.onLogIn.subscribe(data => {
      if (data) {

        // this.connect_session();

      } else {
        this.disconnect();
      }

    });
  }

  private subject: any;
  subScriptionlist: any = {};

  validTypes: any = ['push-newtimelineevent', 'push-data', 'push-newodds', 'push-newmatch', 'push-betstop', 'push-newodds'];

  private connectRabbit(client, user, passwd, fxn) {
    this.checkIdletime = false;
    let me = this;
    if (!client.connected) {
      client.connect(user, passwd, (frame) => {
        fxn(client, this);
      }, (message) => {

        setTimeout(() => { me.connectRabbit(client, user, passwd, fxn); }, 5000);
      }, '/');
    }
  }

  public subscribe(client, me) {
    if (client.connected) {
      let param = { exchange: "flexbet_dashboard", routine: "*.*.two_race" }
      me.sendGlobal(client, param)
    }
  }
  public connectRaces() {
    this.connect(this.configs.PROXIES.Rabbit_Address, this.configs.RABBIT_CONFIG.USER, this.configs.RABBIT_CONFIG.PASSWD, this.subscribe);

  }
  public connect_session() {

    this.connect(this.configs.PROXIES.Rabbit_user_Address, this.configs.RABBIT_CONFIG.ACCUSER, this.configs.RABBIT_CONFIG.ACCPASSWD, this.subscribeToSessionExchange, true);

  }
  public connect(address, user, passwd, fxn, isAccountStomp?) {
    let ws = new WebSocket(address);
    let stompclient = Stomp.over(ws);

    this.connectRabbit(stompclient, user, passwd, fxn);

    stompclient.debug = null;
    if (isAccountStomp)
      this.stompClient = stompclient;
  }
  subscribeToSessionExchange(client, me) {
    if (me.userServices.userData && me.userServices.userData.account_code) {

      let param = { exchange: me.configs.RABBIT_CONFIG.EXCHANGE, routine: me.configs.RABBIT_CONFIG.QUEUE + '.' + me.userServices.userData.account_code }
      me.sendGlobal(client, param)

    }

  }

  public disconnect_rabbit() {
    // this.stompclient.disconnect(() => {});
    return this.subject;
  }
  public disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }


    return this.subject;
  }


  sendGlobal_old(message) {

    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.send("/app/chat", {}, JSON.stringify(message));
    }

  }

  sendGlobal(client, message) {
    let me = this;
    if (client) {
      client.subscribe(`/exchange/${message.exchange}/${message.routine}`, (m) => {
        const reversedText = m.body.split('').join('');
        me.processMessage(reversedText);
      });
    } else {
      setTimeout(() => {
        me.sendGlobal(client, message);
      }, 1000);
    }

  }
  processMatchStatistics(stats) {
    this.sportService.matchStatistics[stats.data.id] = stats.data.statistics;
  }


  async processMessage(rawMsg) {
    this.configs.lastmessagetime = new Date();


    let message = JSON.parse(rawMsg);
    // console.log(message);

    if (message.hasOwnProperty('message')) {
      this.turfService.load = true;
      this.turfService.cagnoteGames = [];

      for (const item of message.message) {
        const cagnote = { reunioun: '', game: '', amount: '' };
        cagnote.reunioun = item.toString().split('-')[0];
        cagnote.amount = item.toString().split(':')[2];
        // let louange1 = item[0].substring(item[0].indexOf('[') + 1, item[0].indexOf(']' ));
        // console.log(louange1)
        const start = item[0].substring(item[0].indexOf('[') + 1, item[0].indexOf(']'));
        // console.log('start' , start);
        cagnote.game = this.configs.games[start.trim().toUpperCase()].trim();

        this.turfService.cagnoteGames.push(cagnote);
      }
      // this.getCagnote.next(this.cagnoteGames)
    }

    if (message.hasOwnProperty('tcourse')) {
      this.turfService.courseChange$.next(message.tcourse);
      this.turfService.message = message.tcourse;
      // message = message.tcourse;
    }

    if (typeof (message) == 'string')
      message = JSON.parse(message);
    if (message.hasOwnProperty('data') && message.data.hasOwnProperty('isExpired') && message.data.isExpired) {
      if (this.userServices.userData.session == message.data.session) {
        if (message.data.isForced) {
          // console.log('force logout');
          this.userServices.logout({ sessionToken: this.userServices.userData.session });
          this.disconnect();
        } else {
          if (this.userServices.userIdleTime < this.userServices.expireTime) {

            this.userServices.renewSession();

          } else {
            this.userServices.userData = undefined;
            this.disconnect();
          }

        }
      } else {
        this.userServices.logout({ sessionToken: this.userServices.userData.session });
        this.disconnect();
      }



    }
    if (message.hasOwnProperty('data') && message.type == 'balance') {
      this.userServices.userData.balances = message.data.balances;
    }
  }



  processplatformStatus(message: any) {
    this.configs.bet_activated = message.data.status;
  }
  updateBalance(message: any) {
    this.userServices.updateUserAttribute('balances', message.data.balances);
  }

  processNewWinners(message: any) {

    if (!this.sportService.latestWinners) {
      this.sportService.latestWinners = [];
    }
    this.sportService.latestWinners.unshift(message.data);
  }
  processBetStop(message: any) {
    this.sportService.cancelledMarkets[message.data.id] = message.data.markets;
  }


  upDateCanCeledMarkets(match, markets: Array<any>) {
    markets.forEach(market => {
      _.remove(this.sportService.cancelledMarkets[match], elemntToRemove => {
        return elemntToRemove.id == market.outcomes[0] ? market.outcomes[0].market.id : market.id;
      });
    });
  }

  processMatchScoreChange(data) {
  }



  updatebetSlipOdds(match: string, marketsWithNewOdds: Array<any>) {

    let betWithPossibleOddChange = [];

    for (const betChoice of this.betService.betChoices) {
      if (betChoice.match.id === match) {
        betWithPossibleOddChange = [betChoice];
        break;
      }
    }

    if (betWithPossibleOddChange.length > 0) {
      betWithPossibleOddChange[0].propositions.forEach(proposition => {
        for (const market of marketsWithNewOdds) {
          if (market.id === proposition.market.id) {
            market.outcomes.forEach(outcome => {
              if (outcome.outcome_id === proposition.outcome_id) {
                if (proposition.odds !== outcome.odds) {
                  proposition.oldOdds = proposition.oldOdds ? proposition.oldOdds : proposition.odds;
                  proposition.odds = outcome.odds;
                  proposition.market = outcome.market;
                  proposition.is_active = outcome.is_active;
                }
              }
            });
            break;
          }
        }

      });
    }

  }

  updateMissingfields(outcome: any, outcome2: any) {
    const Keys = Object.keys(outcome);
    Keys.forEach(key => {
      if (!outcome2.hasOwnProperty(key)) {
        outcome2[key] = outcome[key];
      }
    });
  }
}
