import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function phoneNumberValidator(phoneNumberLength: number, startStrings: string[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        // valid phone number length regex
        const regexLength = phoneNumberLength > 0 ? phoneNumberLength.toString() : "1,"
        const validLengthRegex = new RegExp(`^[0-9]{${regexLength}}$`);
        // valid start digits regex
        const validStartDigitsRegex = new RegExp(`^(${startStrings.join('|')})[0-9]*$`);

        const onlyDigits = /^[0-9]{1,}$/.test(value);

        const rightLength = validLengthRegex.test(value);

        const rightStartDigits = validStartDigitsRegex.test(value);

        const phoneNumberValid = rightLength && rightStartDigits && onlyDigits;
        return !phoneNumberValid ? {
            phoneNumber: {
                onlyDigits: !onlyDigits,
                rightLength: {
                    invalid: !rightLength,
                    value: phoneNumberLength 
                },
                rightStartDigits: {
                    invalid: !rightStartDigits,
                    value: startStrings.join(',') 
                }
            }
        }: null;
    }
}