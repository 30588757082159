import { Injectable, Component, Inject } from "@angular/core";
import { DomService } from "./DOMService";
import { BehaviorSubject } from "rxjs";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfigService } from "../config/config.service";
declare var $: any;
@Injectable()
export class ModalService {
  event: BehaviorSubject<boolean>;

  constructor(private domService: DomService, public dialog: MatDialog,) {}

  private modalElementId = "modal-container";
  private overlayElementId = "overlay";
  private modalBody = "modal-body";
  public isSuccess = false;
  public msg: string;
  public isError = false;
  public title: any;
  public subtitle: any;
  public isOpen = false;
  init(component: any, inputs: any, outputs: object) {
    const componentConfig = {
      inputs: inputs,
      outputs: outputs,
    };
    // const dialogRef = this.dialog.open(component, {
    //   width: '850px',
    //   panelClass: 'no-padding',
    //   height: '70%',
    //   data: inputs
    // });
    this.domService.appendComponentTo(
      this.modalBody,
      component,
      componentConfig
    );
    document.getElementById(this.modalElementId).className = "show";
    document.getElementById(this.overlayElementId).className = "show";
    // this.config.whiteIconTheme
  }

  destroy() {
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = "hidden";
    document.getElementById(this.overlayElementId).className = "hidden";
  }
  showModal() {
    // try {
    //   $("#successDialogue").modal("show");
    // } finally {
    //   try {
    //     $("#exampleModal").show("show");
    //   } finally {
    //     try {
    //       $("#confirmationLoginModal").show("show");
    //     } catch (error) { }
    //   }
    // }
    try {
      $("#successDialogue").modal("show");
      $("#exampleModal").show("show");
      $("#confirmationLoginModal").show("show");
    } catch (error) {
      // console.log("error opening modal", error);

    }
  }
  openDialog(header, msg, isConfirm, extraParam?: string): Promise<any> {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: "400px",

      data: {
        yesClicked: false,
        header: header,
        msg: msg,
        isConfirmDialog: isConfirm,
        extraParam: extraParam,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }
  openDialogcmp(component: any, data): Promise<any> {
    const dialogRef = this.dialog.open(component, {
      width: "80%",
      height: "70%",
      panelClass: "no-padding",
      data: { data: data },
    });

    return dialogRef.afterClosed().toPromise();
  }
}

export interface DialogData {
  yesClicked: boolean;
  isConfirmDialog: boolean;
  header: string;
  msg: string;
  extraParam?: string;
}
@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "dialog.html",
})
export class DialogOverviewExampleDialog {
  constructor( public config: ConfigService,
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.data.yesClicked = false;
    this.dialogRef.close(this.data);
  }
  onYesClick(): void {
    this.data.yesClicked = true;
    this.dialogRef.close(this.data);
  }
}
