import { ResetPassowrdComponent } from './../../components/reset-passowrd/reset-passowrd.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyTransactionsComponent } from './my-transactions/my-transactions.component';
import { AccountsComponent } from './accounts/accounts.component';
import { MyAccountService } from '../../providers/my-account/my-account.service';
import { TranslateModule } from '@ngx-translate/core';
import { MdePopoverModule } from '@material-extended/mde';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from 'src/app/components/components.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ActivateAccountComponent } from 'src/app/components/activate-account/activate-account.component';
@NgModule({
  declarations: [MyAccountComponent, MyProfileComponent, ResetPassowrdComponent,
    ActivateAccountComponent, MyTransactionsComponent, AccountsComponent],
  exports: [MyProfileComponent, MyTransactionsComponent, AccountsComponent],
  entryComponents: [MyAccountComponent, ResetPassowrdComponent, ActivateAccountComponent],
  providers: [MyAccountService],
  imports: [
    FormsModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MdePopoverModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CommonModule
  ]
})
export class MyAccountModule { }
