export const SystemConfigs = [
  { name: 'country_code', key: 'DEFAULT_COUNTRY_CODE', default:  ""},
  // { name: 'site_name', key: 'STR_SITE_NAME', default: "" },
  { name: 'BL_ALLOW_LOGIN_PHONE_NUMBER', key: 'BL_ALLOW_LOGIN_PHONE_NUMBER', default: 0 },
  { name: 'login_required', key: 'B_LOGIN_REQUIRED', default: 0 },
  { name: 'usePromoCode', key: 'USE_PROMO_CODE_REGISTRATION', default: 0 },
  { name: 'quick_signup_enabled', key: 'B_ENABLE_QUICK_SIGNUP', default: 0 },
  { name: 'phoneNumberLength', key: 'PHONE_NUMBER_LENGTH', default: '10' },
  { name: 'currency', key: 'STR_CURRENCY_CODE', default: "" },
  { name: 'min_age', key: 'INT_MIN_AGE', default: 21},
  { name: 'age_verification_required', key: 'AGE_VERIFICATION_REQUIRED', default: 0 },
  { name: 'quick_reg_input_fields', key: 'QUICK_SIGNUP_INPUT_FIELDS', default: ['LOGIN', 'PASSWORD'] },
  { name: 'login_required', key: 'B_LOGIN_REQUIRED', default: 1 },
  { name: 'has_bonuses', key: 'B_HAS_BONUSES', default: 0 },

  // { name: 'nationale_types_offered', key: 'NATIONALE_GAMES_OFFERED', default: "1, 2, 3" },
];

export interface Language {
  name: string,
  code: string,
  active: boolean,
  image: string,
  value: string
 }

export const httpErrCodes = [

]
