import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { ConfigService } from './../../../providers/config/config.service';
import { UserManagerService } from '../../../providers/services/userManager/user-manager.service';
import { Component, OnInit } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SkeletonServiceService } from 'src/app/components/skeleton-service.service';
declare var $: any;
interface schedule {
  period: 'select_period'|'today'|'yesterday'|'this_week'|'last_2_weeks'|'this_month'|'last_3_months'|'this_year'|'last_2_years'; 
  rangeInDays: number;
};
@Component({
  selector: 'app-my-transactions',
  templateUrl: './my-transactions.component.html',
  styleUrls: ['./my-transactions.component.scss']
})
export class MyTransactionsComponent implements OnInit {
  activeHeader: any;
  status: 'all' = 'all';
  startTime: moment.Moment = moment();
  endTime: moment.Moment = moment();
  fromDate: string;
  toDate: string;
  maxDate: string = "";
  minDate: string = "";
  chosenSchedule: schedule;
  schedules: schedule[] = [
    { period: 'select_period', rangeInDays: -1},
    { period: 'today', rangeInDays: 0 },
    { period: 'yesterday', rangeInDays: 1 },
    { period: 'this_week', rangeInDays: 7 },
    { period: 'last_2_weeks', rangeInDays: 14 },
    { period: 'this_month', rangeInDays: 30 },
    { period: 'last_3_months', rangeInDays: 90 },
  ];
  transactions: any =[];
  dateChangedEvent = false;
  imagesRepo: string | undefined;
  cashoutvalue: number | undefined;
  isLoading: boolean;
  isProcessing: boolean;
  activeTransaction = '';
  errorMsg: string;
  params: any;
  constructor(public userManagerService: UserManagerService,
    public modalService: ModalService, public configs: ConfigService,
    public skeleton: SkeletonServiceService,
    private translate: TranslateService) {
    this.activeHeader = this.headers[0];
    this.imagesRepo = this.configs.PROXIES.IMAGES_REPO;
  }

  headers: {name: string; id:number;}[] = [{
    name: 'Tous les paris',
    id: 1
  }, {
    name: 'En cours',
    id: 2
  }, {
    name: 'Cash Out effectué',
    id: 3
  }, {
    name: 'Gagné(s)',
    id: 4
  }, {
    name: 'Perdu(s)',
    id: 5
  }, {
    name: ' Annulé(s)',
    id: 6
  }
  ];
  ngOnInit(){
    this.selectSchedule(this.schedules[1]);
  };

  ngAfterViewInit() {
    this.getHistory();
  }

  setDates() {
    this.fromDate = this.startTime.format("YYYY-MM-DD");
    this.toDate = this.endTime.format("YYYY-MM-DD");
  }

  updateDate(date: string, endTime: boolean){
    if(endTime){
      this.endTime = moment(date, "YYYY-MM-DD");
      this.toDate = this.endTime.format("YYYY-MM-DD");
    }else{
      this.startTime = moment(date, "YYYY-MM-DD");
      this.fromDate = this.startTime.format("YYYY-MM-DD");
    }
  }

  selectSchedule(schedule: schedule){
    this.chosenSchedule = schedule;
    this.endTime = moment();
    this.maxDate = this.endTime.format("YYYY-MM-DD");
    if(schedule.rangeInDays > -1){
      this.startTime = moment(this.endTime).subtract(schedule.rangeInDays, 'days');
      this.minDate = this.startTime.format("YYYY-MM-DD");
    }else {
      this.startTime = moment(this.endTime).subtract(1, 'days');
      this.minDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    }
    this.setDates();
    this.getHistory();
  }

  getHistory() {
    if(!this.configs.useGateway){
      this.params = {
        lg_Customer_Account_ID: this.userManagerService.userData.customer_ID,
        transactiontype: "B",
        day: this.fromDate,
        day2: this.toDate
      }
      this.isLoading = true;

      this.userManagerService.getTransactions(this.params).then(data => {
        if(data.length > 0)
       {
          this.transactions = data;
       }}).finally(()=>{
        this.isLoading = false;
       })
       return
    }
    this.params = {
      uid:  this.userManagerService.userData.customer_ID ,
      begin: new Date(this.fromDate).setHours(0, 0, 0, 0),
      end: new Date(this.toDate).setHours(23, 59, 0, 0),
    };
    this.transactions = [];
    this.isLoading = true;
    this.userManagerService.getTransactions(this.params).then(data => {
      if(data.hasOwnProperty('data') && data.data.length > 0)
     {
        this.transactions = data.data;
     }
      this.isLoading = false;
    }).catch(err => {
      this.transactions = [];
      this.isLoading = false;
    }).finally(() => {

    });
  }

  triggerCashOut(transaction: any, trigger?: MdePopoverTrigger) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.cashoutvalue = undefined;
    $(trigger).dropdown('dispose');
    this.userManagerService.triggerCashout(encodeURIComponent(transaction.refCode)).then(data => {
      if (data.erc == 1) {
        this.cashoutvalue = data.amount;
      } else {
        this.cashoutvalue = undefined;
        this.errorMsg = data.msg;
      }

      this.isProcessing = false;
      $(trigger).dropdown('toggle');
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
    });
  }
  completeCashout(transaction) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.userManagerService.completeCashout(transaction.id, this.cashoutvalue).then(data => {
      if (data && ((data.length > 0 && data[0] && data[0]['14'] === true || data[0]['14'] === 'true') || data.erc == 1)) {
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = 'success';
        this.modalService.isSuccess = true;
      } else {
        this.modalService.isSuccess = false;
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = 'error';
      }

      this.modalService.showModal();
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
      this.modalService.destroy();
    });
  }

}
