import { ModalService } from '../../providers/modal-service/ModalService';
import { UserManagerService } from './../../providers/services/userManager/user-manager.service';
import { Component, OnInit, Input } from '@angular/core';
import { MyAccountService } from '../../providers/my-account/my-account.service';
import { ConfigService } from '../../providers/config/config.service';
import { SkeletonServiceService } from 'src/app/components/skeleton-service.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  @Input() activeTab: number;
  constructor(
    public accountPanelServie: MyAccountService,
    public configService: ConfigService,
    public skeleton: SkeletonServiceService,
    public modalService: ModalService, public userManagerService: UserManagerService) { }
    ext:String =  ""
  ngOnInit() {
    this.accountPanelServie.activeAccountstab = this.activeTab;
    this.ext = this.configService.whiteIconTheme ? "_": ""
  }
  selectTab(tabId) {
    this.accountPanelServie.activeAccountstab = tabId;
  }
}
