import { HttpService } from './../../../wrappers/Http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import { ConfigService } from '../../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class SportServiceService {


  cancelledMarkets: any = {};
  matchStatistics: any = {};
  sports: Array<any> = [];
  groupings: any = {};
  tournaments: any = {};
  lastminuite: Array<any> = [];
  activeSports: Array<any> = [];
  activeLiveSports: Array<any> = [];
  activeTournaments: Array<any> = [];
  activeLiveTournaments: Array<any> = [];
  activeGroup: Array<any> = [];
  activeLiveGroup: Array<any> = [];
  matches: any = {};
  pagedTopMatches: any = {};
  perPageTopMatches: number = 3;
  topMatches: Array<any> = [];
  livematches: any = {};
  activeMatch: any;
  activeLiveMatch: any;
  preMatchSelections: any = {};
  preLiveMatchSelections: any = {};
  arenaData: any = {};
  liveArenaData: any = {};
  prematchIsLoading = false;
  resultatIsLoading = false;
  calendarIsLoading = false;
  liveIsLoading = false;
  marketList: any = {};
  favMarkets: Array<any> = [];
  marketPropositions: any = {};
  selectedmarketGroup: any;
  preselectiontournaments: Array<any> = [];
  preLiveselectiontournaments: Array<any> = [];
  majorTournaments: Array<any> = [];
  tournamentsData: any = [];
  defaultSport: any = {};
  liveData: any = {};
  allSports: any = [];
  sportTournaments: any = [];
  sportResults: any = [];
  eventResultDetails: any = [];
  calendarData: any = [];
  latestWinners: Array<any> = [];
  loadingLiveData: boolean;
  loadingMarkets = false;
  liveSports: Array<any>;
  groupsMarkets: any = {};
  timeLine: any = {};
  private _gratuits: any = [];
  publications: any[];
  publicationCounts: any;


  constructor(public requestHandler: HttpService, private configService: ConfigService) { }

  public get gratuits(): any {
    return this._gratuits;
  }
  public set gratuits(value: any) {
    this._gratuits = value;
  }
  public getSportsList(time?: number) {
    return this.requestHandler.get(this.configService.EndPoints.sports + time + '/sports', {}).then(data => {
      this.sports = data;
    }).catch(err => {
      this.sports = [];
    });
  }
  getLastWinners() {
    return this.requestHandler.Hget(this.configService.PROXIES.turf_gateway, this.configService.EndPoints.LATETS_WINNERS, {}).then(data => {
      // if (data.hasOwnProperty('erc') && data.erc === 1) {
      this.latestWinners = data;
      // }

    });
  }

  public getLiveSportList(time?: number) {
    return this.requestHandler.get(this.configService.EndPoints.sports + '/event/liveSports', {}).then(data => {
      this.liveSports = data.sports;
    }).catch(err => {
      this.liveSports = [];
    });
  }

  getLastMinuites(time): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + time, {}).then(data => {
      this.lastminuite = data;
    }).catch(err => {
      this.lastminuite = [];
    });
  }

  getGrouping(id: any, time: number = 0): Promise<any> {

    return this.requestHandler.get(this.configService.EndPoints.sports + id + '/' + time + '/categories', {});
  }
  getLiveGrouping(id: any, time: number = 0): Promise<any> {

    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + id + '/liveCategories', {});
  }
  getTournaments(id: any, currentTime: number): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + id + '/' + currentTime + '/tournaments', {});
  }
  getLiveTournaments(id: any, currentTime: number): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + id + '/liveTournaments', {});
  }
  getMatches(id: any, currentTime: number): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + id + '/' + currentTime + '/matches', {});
  }
  getOutRights(id: any, currentTime: number): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + 'tournament/' + id + '/outrights', {});
  }
  getLiveMatches(id: any, currentTime: number): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + id + '/liveMatches', {});
  }

  async getTopMatches(): Promise<any> {
    const data = await this.requestHandler.get(this.configService.EndPoints.sports + 'event/topMatches', {});
    this.pagedTopMatches = _.chunk(data, this.perPageTopMatches)
    this.topMatches = data;
  }

  getMajorTournaments(currentTime): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.sports + currentTime + '/majorTournaments', {}).then(data => {
      this.majorTournaments = data;
    });
  }

  getEventsArenaDetails(id: any, params = {}) {
    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + id + '/' + '/information', params).then(data => {
      this.arenaData = data;
      this.timeLine[id] = data.timeline;
    }).catch(err => {
      this.arenaData = undefined;
    });

  }
  getLiveArenaDetails(id: any) {
    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + id + '/' + '/information', {}).then(data => {
      this.arenaData = data;
      this.timeLine[id] = data.timeline;
    }).catch(err => {
      this.liveArenaData = undefined;
    });

  }

  getEventsMarkets(id: any) {
    if (this.marketList.hasOwnProperty(id)) {
      this.marketPropositions = this.marketList[id][0] ? this.marketList[id][0]['markets'] : [];
      this.selectedmarketGroup = this.marketList[id].length > 0 ? this.marketList[id][0].id : undefined;
      return Promise.resolve(this.marketList);
    }
    this.loadingMarkets = true;
    return this.requestHandler.get(this.configService.EndPoints.sports + 'event/0/' + id  + '/market', {}).then(data => {
      this.marketList[id] = data;

      if (!this.groupsMarkets[id]) {
        this.groupsMarkets[id] = {};
      }
      if (!this.groupsMarkets[id][data[0].id]) {
        this.groupsMarkets[id][data[0].id] = {};
      }
      this.groupsMarkets[id][data[0].id] = data[0]['markets'];

      if (this.marketList[id].length > 0) {
        this.marketPropositions = this.marketList[id][0]['markets'];
        this.selectedmarketGroup = this.marketList[id][0].id;

      }
    }).catch(err => {
      this.marketList[id] = [];
    }).finally(() => {
      // console.log(this.groupsMarkets,this.marketList);
      this.loadingMarkets = false;
    });

  }

  getEventsMarketsInGroup(matchId: any, groupID: any) {
    // console.log(groupID);
    this.marketPropositions = [];
    this.selectedmarketGroup = groupID;
    // return;
    if (matchId in this.groupsMarkets) {
      if (groupID in this.groupsMarkets[matchId]) {
        return Promise.resolve(this.groupsMarkets[matchId][groupID]);
      } else {
        this.groupsMarkets[matchId][groupID] = [];
      }
    } else {
      this.groupsMarkets[matchId] = {};
      this.groupsMarkets[matchId][groupID] = [];
    }
    this.groupsMarkets[matchId][groupID] = this.groupsMarkets[matchId]['0'].filter(market => {
      return market.group_id == groupID;
    })
    return Promise.resolve(this.groupsMarkets[matchId][groupID]);
    // this.loadingMarkets = true;
    // return this.requestHandler.get(this.configService.EndPoints.sports + 'event/' + groupID + '/' + matchId + '/market', {}).then(data => {

    //   this.loadingMarkets = false;
    //   this.groupsMarkets[matchId][groupID] = data[0].markets;
    //   this.marketPropositions = data[0].markets;
    //   let groupIndex = 0;
    //   this.marketList[this.activeMatch.id].filter(group => {
    //     if (group.id === groupID) {
    //       this.marketList[this.activeMatch.id][groupIndex].markets = data[0].markets;
    //     }
    //     groupIndex++;
    //     return group.id === group;
    //   });
    // }).catch(err => {
    //   if (!this.marketPropositions.hasOwnProperty(matchId)) {
    //     this.marketPropositions[matchId] = {};
    //   }
    //   this.marketPropositions[matchId] = [];
    // });

  }
  getSportsMatches(time: any, sportID: any) {
    return this.requestHandler.get(this.configService.EndPoints.sports + time + '/' + sportID, {});
  }
  search(searchKey: any): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.events + '/' + searchKey + '/search', {});
  }

  addFavourites(market) {
    let index = 0;
    for (const fav of this.favMarkets) {
      if (fav.id === market.id) {
        this.favMarkets.splice(index, 1);
        return true;
      }
      index++;
    }
    this.favMarkets.unshift(market);
  }
  marketIsFavourite(market) {
    for (const fav of this.favMarkets) {
      if (fav.id === market) {
        return true;
      }
    }
    return false;
  }
  getOutComesforMatch(match, market) {
    if (match.default_market === market) {
      match.outcomes = match.outcome;
      return match;
    }

    for (const groupsMarket of this.groupsMarkets[match.id]['0']) {
      if (groupsMarket.id === market) {
        match.outcomes = groupsMarket.outcomes;
        return match;
        break;
      }
    }
  }
  getLiveMatchData() {
    return new Promise((resolve, reject) => {
      this.requestHandler.get(this.configService.EndPoints.LIVE_DATA, {}).then(data => {
        this.liveData = data;
        this.liveSports = this.liveData.sports;
        resolve(this.liveData);
      }, error => {
        reject(error);
      });
    });
  }
  loadPublication(params: any = {}, more = ''){
    params.status = true;
    params.time = new Date().getTime()
    return this.requestHandler.Hget(this.configService.PROXIES.userService, this.configService.EndPoints.publication  , params).then(response => {
      if (more === 'load-more') {
        // this.publications  = [...this.publications, ...response.data];
        this.publications.push(response.data)
        this.publicationCounts = response.count;
      } else {
        this.publications = response.data;
        this.publicationCounts = response.count;
      }

  });
  }
  public getAllSports(): Promise<any> {
    return this.requestHandler.get(this.configService.EndPoints.all_sports, {}).then(data => {
      this.allSports = data;
    }).catch(err => {
      this.allSports = [];
    });
  }

  public getSportTournaments(sportID: any) {
    return this.requestHandler.get(this.configService.EndPoints.sports + sportID + '/tournaments', {}).then(data => {
      this.sportTournaments = data;
    }).catch(err => {
      this.sportTournaments = [];
    });
  }
  getLatestRestults(id: any) {
    return this.requestHandler.get(this.configService.EndPoints.LATEST_RESULTS + '/' + id + '/5', { limit: 5 });
  }
  public getResults(tournamentID: string, limit: any, params: any) {
    let url = '';
    if (tournamentID && tournamentID.length > 0) {
      url = this.configService.EndPoints.sports + 'results/' + tournamentID + '/' + limit;
    } else {
      url = this.configService.EndPoints.sports + 'results/' + limit;
    }
    return this.requestHandler.get(url, params).then(data => {
      this.sportResults = data;
    }).catch(err => {
      this.sportResults = [];
    });

  }

  public getResultDetails(eventId: any) {
    return this.requestHandler.get(this.configService.EndPoints.events + '/' + eventId + '/winningMarkets', {}).then(data => {
      this.eventResultDetails = data;
    }).catch(err => {
      this.eventResultDetails = [];
    });
  }

  public getStatistics(match: any) {
    return this.requestHandler.get(this.configService.EndPoints.events + '/' + match + '/statistics', {}).then(data => {
      this.matchStatistics[match] = data;
    });
  }

  public calendar(params) {
    return this.requestHandler.get(this.configService.EndPoints.sports + this.configService.EndPoints.calendar, params).then(data => {
      this.calendarData = data;
    }).catch(err => {
      this.calendarData = [];
    });
  }
  marketInList(market: any, markets: Array<any>) {
    return markets ? markets.filter(element => {
      return element.id === market.specifier ? market.id + "*" + market.specifier : market.id;
    }).length > 0 : false;
  }

  getSliders() {
    return this.requestHandler.get(this.configService.EndPoints.cagnottes, {});
  }

  getGratuits() {
    this.requestHandler.get(this.configService.EndPoints.gameList, {}).then(data => {
      this.gratuits = data;
      // console.log(this.gratuits);
    });
  }
}
