import { PipesModule } from './../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { TawkComponent } from './tawk/tawk.component';


@NgModule({
  declarations: [
TawkComponent,


  ],
  exports: [
    TawkComponent
  ],
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    PipesModule,
    QRCodeModule,
    NgxPrintModule,
    FormsModule
  ]
})
export class ComponentsModule { }
