import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.sort((item1, item2) => {
      return item1[args] > item2[args] ? item1[args] : item2[args];
    }) : [];
  }

}
