import { UserManagerService } from './../services/userManager/user-manager.service';
import { HttpService } from './../../wrappers/Http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { resolve } from 'url';
import { el } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  getOTP(withdrawalAmt: string, Withdrawal = false): Promise<any> {
    var data = {
      sessionId: this.userService.userData.session,
      amount: withdrawalAmt,
      isWithdrawal: Withdrawal
    }
    return new Promise((resolve, reject) => {
      this.requestHandler.authPost(this.configService.PROXIES.sportcash, this.configService.EndPoints.REQUEST_OTP, data).then(response => {
        resolve(response);
        // if (response.hasOwnProperty("erc") && response.erc == '1') {
        //   resolve(response)
        // }
        // else {
        //   reject(response)
        // }
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  public paymentProviders: any = {};
  private _activeAccountstab = 1;
  private _usersbanks: Array<any>;
  public get usersbanks(): Array<any> {
    return this._usersbanks;
  }
  public set usersbanks(value: Array<any>) {
    this._usersbanks = value;
  }
  private _tabs: Array<any> = [];
  public get tabs(): Array<any> {
    return this._tabs;
  }
  public set tabs(value: Array<any>) {
    this._tabs = value;
  }

  public get activeAccountstab(): number {
    return this._activeAccountstab;
  }
  public set activeAccountstab(value: number) {
    this._activeAccountstab = value;
  }
  constructor(private requestHandler: HttpService, private configService: ConfigService, private userService: UserManagerService) { }

  getProviders(id: any): Promise<any> {
    if(!this.configService.useGateway){
      if(!this.paymentProviders || !(this.paymentProviders.length>0))
      {
        const params: any = {};
        params.channel = 7;
        params.versioncode = 3;
        params.date_jr = '';
        this.requestHandler
        .Hget(this.configService.PROXIES.turf_gateway, this.configService.TurfEndpoints.getActiveGames, params)
        .then((response) => {
          this.paymentProviders['1'] = response['data']['AC'][0];
          this.paymentProviders['2'] = response['data']['AC'][0];
        });
      }
      return new Promise((resolve, reject)=>{
        resolve("done");
      });
    }

    // }else{
      const api = id === 1 ? 'cashInProviders' : 'cashOutProviders';
      return this.requestHandler.get_request(this.configService.PROXIES.userService ,this.configService.EndPoints.providers + api, {}).then(response => {
        const providers = [];
        response.data.forEach(element => {
          if (element.hasOwnProperty('sub_providers') && element.sub_providers.length > 0) {
            element.sub_providers.forEach(subProv => {
              subProv.pdv = element.code;
              subProv.min = Number(subProv.min);
              subProv.max = Number(subProv.max);
              providers.push(subProv);
            });
          } else {
            element.pdv = element.code;
            element.min = Number(element.min);
            element.max = Number(element.max);
            providers.push(element);
          }

        });
        this.paymentProviders[id] = providers;
        // console.log((id === 1 ? 'cashInProviders' : 'cashOutProviders') + " (" + id + "): ", providers);
        // console.log("all providers", this.paymentProviders);

      });
    // }

  }
  triggerPMUGTransactions(arg0: any, accountParams: any) {
    accountParams.lg_Channel_ID=7
    accountParams.lang=this.userService.userData.lang
    return this.requestHandler.get_request(this.configService.PROXIES.userService ,  this.configService.EndPoints[arg0], accountParams);

  }
  confirmRetrait(arg0: any, accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService + '/', this.configService.EndPoints[arg0], accountParams);
  }
  confirmRecharge(arg0: any, accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService + '/', this.configService.EndPoints[arg0], accountParams);
    // return this.requestHandler.authPost(this.configService.PROXIES.userService + '', this.configService.EndPoints[arg0], accountParams);
  }
  getUserBanks() {
    this.requestHandler.get_request(this.configService.PROXIES.userService , 'account/' + this.userService.userData.customer_ID ? this.userService.userData.customer_ID : this.userService.userData.lg_AGENT_ID, {}).then(responseData => {
      this.usersbanks = responseData;
    });
  }
  validateOTP(accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService + '', this.configService.EndPoints[accountParams['pdvurl'] + '_CONFIRMATION'], accountParams);
  }
}
