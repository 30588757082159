import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/wrappers/Http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpService, private configs: ConfigService) { }




  async getTokenRequest(){
    let params:any = { refreshToken: this.configs.USER_AUTHORISATION.refresh_token }
    // let api = this.configs.PROXIES.userService.endsWith('v2') ? this.configs.PROXIES.userService.split('v2/')[0] : this.configs.PROXIES.userService
    await this.http.authPost2(this.configs.PROXIES.userService, this.configs.EndPoints.refreshToken, params, "").then(res => {

      if (res.erc == 1 && res.data) {
        // this.logger.log("new token loaded", res)
        this.configs.USER_AUTHORISATION.access_token = res.data.token
        this.configs.USER_AUTHORISATION.expiry =  new Date(res.data.expire_at)
        // return res.data.token
      // }else{
      //   this.configs.USER_AUTHORISATION.access_token = '';
      //   this.configs.USER_AUTHORISATION.expiry = new Date();
      //   return "";
      }
    }).catch(err=>{
      // this.logger.log('token request err', err)
    })
  }
}
