import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MyAccountModule } from 'src/app/pages/my-account/my-account.module';
@NgModule({
  declarations: [FooterComponent, HeaderComponent],
  exports: [FooterComponent, HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    MyAccountModule,
    TranslateModule.forChild(),
    RouterModule
  ]
})
export class SkeletonComponentsModule { }
